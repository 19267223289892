import React from 'react';
import {Box, Chip, Typography} from "@mui/material";
import {helper} from "../../../utils/helper.js";

const ValueLabelRow = ({label, value, ...props}) => {
    return (
      <Box sx={{display:'flex'}}>
        <Typography sx={{paddingRight:'.25rem'}}><b>{label} :</b></Typography>
        <Typography>{value}</Typography>
      </Box>
    )
}

export default ValueLabelRow;