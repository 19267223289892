import {heuresConstants} from './heures.constants.js';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {
    heures: null,
    ficheChantier: null,
    loading: false,
    error: null,
    exportLoading: false,
    periodePaies: null,
    periodePaie: null
}

export function heures(state = initialState, action) {
    switch (action.type) {

        // ========================
        // Export existant
        // ========================
        case heuresConstants.EXPORT_SUIVI_HEURES_REQUEST:
            return {
                ...state,
                exportLoading: true
            };
        case heuresConstants.EXPORT_SUIVI_HEURES_SUCCESS:
        case heuresConstants.EXPORT_SUIVI_HEURES_FAILURE:
            return {
                ...state,
                exportLoading: false
            };

        // ========================
        // FEUILLE DE TEMPS (update) existant
        // ========================
        case heuresConstants.FEUILLEDETEMPS_UPDATE_SUCCESS:
            const updatedFdt = action.fdt;
            const start = new Date(updatedFdt.heureDebut);
            const end = new Date(updatedFdt.heureFin);
            const workMinutes = (end - start) / (1000 * 60) - (updatedFdt.tempsPauseMinutes || 0);
            return {
                ...state,
                heures: state.heures.map(row => ({
                    ...row,
                    suivi: row.suivi.map(day => {
                        if (day.details?.idFdt === updatedFdt.id) {
                            return {
                                ...day,
                                details: {
                                    ...day.details,
                                    debut: updatedFdt.heureDebut,
                                    fin: updatedFdt.heureFin,
                                    tempsPauseMinutes: updatedFdt.tempsPauseMinutes,
                                    workMinutes: workMinutes,
                                    validatedAt: updatedFdt.validatedAt
                                }
                            };
                        }
                        return day;
                    })
                }))
            };

        case heuresConstants.FEUILLEDETEMPS_UPDATE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        // ========================
        // FETCH HEURES
        // ========================
        case heuresConstants.HEURES_FETCH_REQUEST:
        case heuresConstants.FICHE_CHANTIER_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case heuresConstants.HEURES_FETCH_SUCCESS:
            return {
                ...state,
                heures: action.heures,
                loading: false,
                error: null
            };
        case heuresConstants.HEURES_FETCH_FAILURE:
        case heuresConstants.FICHE_CHANTIER_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case heuresConstants.FICHE_CHANTIER_FETCH_SUCCESS:
            return {
                ...state,
                ficheChantier: action.ficheChantier,
                loading: false,
                error: null
            };

        // ========================
        // PERIODES PAIE : FETCH LIST
        // ========================
        case heuresConstants.PERIODE_PAIES_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                periodePaies: action.periodePaies,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // ========================
        // PERIODES PAIE : FETCH ONE
        // ========================
        case heuresConstants.PERIODE_PAIES_FETCH_ONE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_FETCH_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                periodePaie: action.periodePaie,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_FETCH_ONE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // ========================
        // PERIODES PAIE : CREATE
        // ========================
        case heuresConstants.PERIODE_PAIES_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                periodePaies: null,
                periodePaie: null,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // ========================
        // PERIODES PAIE : UPDATE
        // ========================
        case heuresConstants.PERIODE_PAIES_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_UPDATE_SUCCESS:

            return {
                ...state,
                loading: false,
                periodePaies: null,
                periodePaie: null,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // ========================
        // PERIODES PAIE : DELETE
        // ========================
        case heuresConstants.PERIODE_PAIES_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case heuresConstants.PERIODE_PAIES_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                periodePaies: state.periodePaies?.filter(
                    (p) => p.id !== action.id
                ),
                periodePaie: null,
                error: null
            };
        case heuresConstants.PERIODE_PAIES_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        // ========================
        // SAISIE DES HEURES
        // ========================
        case heuresConstants.POSTE_CREATE_REQUEST:
        case heuresConstants.POSTE_CREATE_SUCCESS:
            return {
                ...state,
                loading: true
            }
        case heuresConstants.POSTE_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        // ========================
        // CLEAR / LOGOUT
        // ========================
        case heuresConstants.HEURES_CLEAR_REQUEST:
            return {
                ...initialState
            };
        case authenticationConstants.LOGOUT:
            return {
                ...initialState
            };

        // ========================
        // DEFAULT
        // ========================
        default:
            return state;
    }
}

