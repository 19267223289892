export const rhdataConstants = {
    RHDATA_FETCH_REQUEST: "RHDATA_FETCH_REQUEST",
    RHDATA_FETCH_SUCCESS: "RHDATA_FETCH_SUCCESS",
    RHDATA_FETCH_FAILURE: "RHDATA_FETCH_FAILURE",

    RHDATA_FETCH_ONE_REQUEST: "RHDATA_FETCH_ONE_REQUEST",
    RHDATA_FETCH_ONE_SUCCESS: "RHDATA_FETCH_ONE_SUCCESS",
    RHDATA_FETCH_ONE_FAILURE: "RHDATA_FETCH_ONE_FAILURE",

    RHDATA_CREATE_REQUEST: "RHDATA_CREATE_REQUEST",
    RHDATA_CREATE_SUCCESS: "RHDATA_CREATE_SUCCESS",
    RHDATA_CREATE_FAILURE: "RHDATA_CREATE_FAILURE",

    RHDATA_UPDATE_REQUEST: "RHDATA_UPDATE_REQUEST",
    RHDATA_UPDATE_SUCCESS: "RHDATA_UPDATE_SUCCESS",
    RHDATA_UPDATE_FAILURE: "RHDATA_UPDATE_FAILURE",

    RHDATA_DELETE_REQUEST: "RHDATA_DELETE_REQUEST",
    RHDATA_DELETE_SUCCESS: "RHDATA_DELETE_SUCCESS",
    RHDATA_DELETE_FAILURE: "RHDATA_DELETE_FAILURE",

    RHDATA_CLEAR_REQUEST: "RHDATA_CLEAR_REQUEST",

};
