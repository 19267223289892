import React, {useState} from 'react';
import './PhotoCarousel.css';
import Carousel from "react-material-ui-carousel";
import {Box, Modal, Paper, Typography} from "@mui/material";
import Button from "@mui/material/Button";

function PhotoCarousel({photoArray, assetsUrl, headerStyle, label, clickable = true, ...props}) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [rotation, setRotation] = useState(0); // Gère la rotation de la photo

    const handleClickPhoto = (photo) => {
        if (clickable) {
            setSelectedPhoto(photo);
            setRotation(0); // Réinitialise la rotation à 0
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedPhoto(null);
    };


    const handleRotatePhoto = () => {
        setRotation((prev) => (prev + 90) % 360); // Incrémente de 90° (rotation circulaire)
    };

    return (
        <Paper className={"carousel-wrapper"}>
            <Typography variant={"h6"} className={"carousel-label " + headerStyle}>{label}</Typography>
            {(!photoArray || photoArray?.length === 0) &&
                <Typography variant={"body1"} sx={{textAlign: 'center', padding: '1rem !important'}}>
                    Aucune photo
                </Typography>
            }
            {photoArray?.length > 0 &&
                <Carousel
                    className={"carousel-container"}
                    animation={"slide"}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'var(--thm-black)',
                            opacity: 1
                        }
                    }}
                    indicatorIconButtonProps={{
                        style: {}
                    }}
                >
                    {
                        photoArray?.map((photo, i) => {
                            return (
                                <img key={i}
                                     src={assetsUrl + photo}
                                     alt={"Intervention photo"}
                                     className={"intervention_photo"}
                                     onClick={() => handleClickPhoto(photo)}
                                     style={{cursor: 'pointer'}}
                                />
                            )
                        })
                    }
                </Carousel>
            }

            {/* Modal pour afficher la photo en grand */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="large-photo-modal"
                aria-describedby="large-photo-modal-description"
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        outline: 'none',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        padding: '1rem',
                        borderRadius: '8px'
                    }}
                >
                    {/* Boutons d'action */}
                    <Box sx={{marginBottom: '1rem', display: 'flex', gap: '1rem'}}>
                        <Button
                            variant={"contained"}
                            onClick={handleRotatePhoto}
                            style={{padding: '0.5rem 1rem'}}>
                            Tourner
                        </Button>
                    </Box>

                    <img
                        src={assetsUrl + selectedPhoto}
                        alt="Large intervention photo"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            borderRadius: '8px',
                            transform: `rotate(${rotation}deg)`,
                            transition: 'transform 0.3s ease'

                        }}
                    />
                </Box>
            </Modal>

        </Paper>

    );
}

export default PhotoCarousel;
