import {quartixConstants} from "./quartix.constants.js";
import {quartixService} from "./quartix.service.js";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";
import {da} from "date-fns/locale";

export const quartixActions = {
    fetchEngineTimes,
};

function fetchEngineTimes(date, vehicleId) {
    return dispatch => {
        dispatch(request());

        quartixService.fetchEngineTimes(date, vehicleId)
            .then(
                data => {
                    dispatch(success(vehicleId, date, data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_REQUEST}
    }

    function success(vehicleId, date, data) {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_SUCCESS, payload: {vehicleId, date, engineTimes: data}}
    }

    function failure(error) {
        return {type: quartixConstants.ENGINE_TIMES_FETCH_FAILURE, error}
    }
}

