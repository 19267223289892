import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Grid} from "@mui/material";
import Loader from "../../layout/loading/loader.js";
import {helper} from "../../../../utils/helper.js";
import moment from "moment-timezone";

function FicheChantierTable({loading, ficheChantier}) {
    const columns = [
        {key: "date", label: ""},
        {key: "heureDebut", label: "Heure Début"},
        {key: "heureFin", label: "Heure Fin"},
        {key: "pauseMinutes", label: "Temps de Pause"},
        {key: "equipier", label: "Équipier"},
        {key: "laveuse", label: "Laveuse"},
        {key: "tourneeLibelle", label: "Tournée"},
        {key: "client", label: "Client"},
    ];

    const daysOfWeek = [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
    ];

    const getLaveuseLibelle = (laveuseDebut, laveuseFin) => {
        let label = laveuseDebut;
        if ((laveuseFin !== "N/A") && (laveuseDebut !== laveuseFin)) {

            label += " | " + laveuseFin
        }

        return label
    }

    const getTempsPauseLabel = (tempsPause) => {
        if (!tempsPause) return "N/A";
        if (tempsPause < 60) {
            return `${tempsPause} minute${tempsPause > 1 ? 's' : ''}`;
        } else {
            const heures = Math.floor(tempsPause / 60);
            const minutes = tempsPause % 60;
            return `${heures} heure${heures > 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} minute${minutes > 1 ? 's' : ''}` : ''}`;
        }
    };

    const getDateRangeLabel = () => {
        if (!ficheChantier?.ficheChantier || ficheChantier?.ficheChantier.length === 0) return "Pas de dates disponibles";

        const firstDate = ficheChantier?.ficheChantier[0]?.date;
        const lastDate = ficheChantier?.ficheChantier[ficheChantier?.ficheChantier.length - 1]?.date;

        if (firstDate && lastDate) {
            return `Semaine ${moment(firstDate).week()} du ${moment(firstDate).format("DD/MM/YYYY")} au ${moment(lastDate).format("DD/MM/YYYY")}`;
        }

        return "Dates invalides";
    };

    const getTourneeLibelle = (details) => {
        let tourneeLibelle = details?.tourneeLibelle
        if (tourneeLibelle) {
            let nbBacs = details?.nbBacs;
            if (nbBacs) {
                return tourneeLibelle + " " + nbBacs + " bacs"
            } else {
                return tourneeLibelle
            }
        }

        return '-'
    }

    return (
        <Box>
            {loading && <Loader/>}
            {!loading &&
                <Grid container className={"mt-2"}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>
                            Fiche de suivi de chantier : {ficheChantier.operateur}
                        </Typography>
                        <Typography variant={"h6"}>
                            {getDateRangeLabel()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        Export
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    {columns.map((column) => (
                                        <TableCell key={column.key} align="center">
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ficheChantier?.ficheChantier.map((fiche, index) => {
                                    const details = fiche.details || {};
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="right">
                                                <b>{daysOfWeek[index]}</b>
                                            </TableCell>
                                            <TableCell align={"left"}>
                                                {helper.formatDate(fiche.date) || "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {details.heureDebut ? helper.getFormattedTime(moment.utc(details.heureDebut)) : "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {details.heureFin ? helper.getFormattedTime(moment.utc(details.heureFin)) : "-"}
                                            </TableCell>
                                            <TableCell align="center">
                                                {getTempsPauseLabel(details.pauseMinutes)}
                                            </TableCell>
                                            <TableCell align="center">{details.equipier || "-"}</TableCell>
                                            <TableCell align="center">
                                                {getLaveuseLibelle(details.laveuseDebut, details.laveuseFin)}
                                            </TableCell>
                                            <TableCell align="center">{getTourneeLibelle(details)}</TableCell>
                                            <TableCell align="center">{details.client || '-'}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            }

        </Box>
    );
}

export default FicheChantierTable;

