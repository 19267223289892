import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const heuresService = {
    fetchHeures,
    updateFeuilleDeTemps,
    fetchFicheChantier,
    exportSuiviHeures,
    fetchPeriodePaies,
    fetchPeriodePaie,
    createPeriodePaie,
    updatePeriodePaie,
    createPoste
};

function fetchHeures(startDate, endDate) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_RH_SUIVI_HEURES;
    url = url.replace(":startDate", startDate)
    url = url.replace(":endDate", endDate)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(heures => {
            return heures
        });
}

function updateFeuilleDeTemps(id, values) {
    const requestOptions = patchHeaders(values)

    let url = Constants.API_ROUTE_FEUILLEDETEMP;
    url = url.replace(":id", id)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(heures => {
            return heures
        });
}

function fetchFicheChantier(startDate, endDate, operateurId) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_RH_FICHE_CHANTIER;
    url = url.replace(":startDate", startDate)
    url = url.replace(":endDate", endDate)
    url = url.replace(":operateurId", operateurId)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(ficheChantier => {
            return ficheChantier
        });
}

function exportSuiviHeures(start, end) {
    const requestOptions = postHeaders({start, end})

    let url = Constants.API_ROUTE_EXPORT_SUIVI_HEURES

    return fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Erreur ${response.status}: ${response.statusText}`);
            }
            return response.blob(); // Retourne un fichier binaire (Blob)
        })
        .then(blob => {
            // Crée un lien de téléchargement
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `fiche_suivi_${start}_${end}.xlsx`); // Nom du fichier
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Nettoyage
        })
        .catch(error => {
            console.error('Erreur lors de l\'export du fichier Excel:', error);
        });
}

function fetchPeriodePaies() {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_PERIODE_PAIES;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data["hydra:member"]
        });
}

function fetchPeriodePaie(id) {
    const requestOptions = fetchHeaders()
    let url = Constants.API_ROUTE_PERIODE_PAIE;
    url = url.replace(":id", id)
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data
        });
}

function createPeriodePaie(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_PERIODE_PAIES;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updatePeriodePaie(id, values) {
    const requestOptions = patchHeaders(values)

    let url = Constants.API_ROUTE_PERIODE_PAIE;
    url = url.replace(":id", id)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deletePeriodePaie(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_PERIODE_PAIE;
    url = url.replace(":id", id)

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function createPoste(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_RH_CREATE_FDT

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            let errorMessage;
            
            if (data && data.message) {
                errorMessage = data.message;
            } else if (data && data.error) {
                errorMessage = data.error;
            } else if (data && typeof data === 'string') {
                errorMessage = data;
            } else {
                errorMessage = response.statusText || 'Une erreur est survenue';
            }

            return Promise.reject(errorMessage);
        }

        return data;
    });
}
