import {usersConstants} from "./users.constants";
import {usersService} from "./users.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const usersActions = {
    fetchUsers,
    fetchUser,
    deleteUser,
    createUser,
    updateUser,
    fetchExploitants,
    createExploitant,
    updateExploitant,
    fetchClients,
    createClient,
    updateClient,
    fetchOperateurs,
    createOperateur,
    updateOperateur,
    createLogo,
    clearUser,
};

function fetchUsers(pagination = false) {
    return dispatch => {
        dispatch(request());

        usersService.fetchUsers(pagination)
            .then(
                users => {
                    let res = users;
                    try {
                        res = users.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_FETCH_REQUEST}
    }

    function success(users) {
        return {type: usersConstants.USERS_FETCH_SUCCESS, users}
    }

    function failure(error) {
        return {type: usersConstants.USERS_FETCH_FAILURE, error}
    }
}

function fetchUser(type, id) {
    return dispatch => {
        dispatch(request());

        if (type === "user") {
            usersService.fetchUser(id)
                .then(
                    user => {
                        dispatch(success(user));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                    }
                );
        } else if (type === "operateur") {
            usersService.fetchOperateur(id)
                .then(
                    user => {
                        dispatch(success(user));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                    }
                );
        } else if (type === "client") {
            usersService.fetchClient(id)
                .then(
                    user => {
                        dispatch(success(user));
                    },
                    error => {
                        dispatch(failure(error.toString()));
                    }
                );
        }

    };

    function request() {
        return {type: usersConstants.USERS_FETCH_ONE_REQUEST}
    }

    function success(user) {
        return {type: usersConstants.USERS_FETCH_ONE_SUCCESS, user}
    }

    function failure(error) {
        return {type: usersConstants.USERS_FETCH_ONE_FAILURE, error}
    }
}

function deleteUser(id, navigate) {
    return dispatch => {
        dispatch(request());

        usersService.deleteUser(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: usersConstants.USERS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été supprimé.")
        return {type: usersConstants.USERS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: usersConstants.USERS_DELETE_FAILURE, error}
    }
}

function createUser(values, navigate) {
    return dispatch => {
        dispatch(request());

        usersService.createUser(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été créé.")
        return {type: usersConstants.USERS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: usersConstants.USERS_CREATE_FAILURE, error}
    }
}

function updateUser(values, navigate) {
    return dispatch => {
        dispatch(request());
        usersService.updateUser(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été modifié.")
        return {type: usersConstants.USERS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: usersConstants.USERS_UPDATE_FAILURE, error}
    }
}

function fetchExploitants(pagination = false) {
    return dispatch => {
        dispatch(request());

        usersService.fetchExploitants(pagination)
            .then(
                users => {
                    let res = users;
                    try {
                        res = users.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.EXPLOITANTS_FETCH_REQUEST}
    }

    function success(users) {
        return {type: usersConstants.EXPLOITANTS_FETCH_SUCCESS, users}
    }

    function failure(error) {
        return {type: usersConstants.EXPLOITANTS_FETCH_FAILURE, error}
    }
}

function createExploitant(values, navigate) {
    return dispatch => {
        dispatch(request());

        usersService.createExploitant(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été créé.")
        return {type: usersConstants.USERS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: usersConstants.USERS_CREATE_FAILURE, error}
    }
}

function updateExploitant(values, id) {
    return dispatch => {
        dispatch(request());
        usersService.updateExploitant(values, id)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été modifié.")
        return {type: usersConstants.USERS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: usersConstants.USERS_UPDATE_FAILURE, error}
    }
}

function fetchClients(pagination = false) {
    return dispatch => {
        dispatch(request());

        usersService.fetchClients(pagination)
            .then(
                clients => {
                    dispatch(success(clients));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.CLIENTS_FETCH_REQUEST}
    }

    function success(clients) {
        return {type: usersConstants.CLIENTS_FETCH_SUCCESS, clients}
    }

    function failure(error) {
        return {type: usersConstants.CLIENTS_FETCH_FAILURE, error}
    }
}

function createClient(values, navigate) {
    return dispatch => {
        dispatch(request());

        usersService.createClient(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été créé.")
        return {type: usersConstants.USERS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: usersConstants.USERS_CREATE_FAILURE, error}
    }
}

function updateClient(values, id) {
    return dispatch => {
        dispatch(request());
        usersService.updateClient(values, id)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été modifié.")
        return {type: usersConstants.USERS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: usersConstants.USERS_UPDATE_FAILURE, error}
    }
}

function fetchOperateurs(filter = null) {
    return dispatch => {
        dispatch(request());

        usersService.fetchOperateurs(filter)
            .then(
                operateurs => {
                    dispatch(success(operateurs));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.OPERATEURS_FETCH_REQUEST}
    }

    function success(operateurs) {
        return {type: usersConstants.OPERATEURS_FETCH_SUCCESS, operateurs}
    }

    function failure(error) {
        return {type: usersConstants.OPERATEURS_FETCH_FAILURE, error}
    }
}

function createOperateur(values) {
    return dispatch => {
        dispatch(request());

        usersService.createOperateur(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été créé.")
        return {type: usersConstants.USERS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: usersConstants.USERS_CREATE_FAILURE, error}
    }
}

function updateOperateur(values, id) {
    return dispatch => {
        dispatch(request());
        usersService.updateOperateur(values, id)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_USERS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: usersConstants.USERS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("L'utilisateur a bien été modifié.")
        return {type: usersConstants.USERS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: usersConstants.USERS_UPDATE_FAILURE, error}
    }
}

function createLogo(values) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request());

            usersService.createLogo(values)
                .then(
                    (logo) => {
                        dispatch(success());
                        resolve(logo)
                    },
                    error => {
                        dispatch(failure(error.toString()));
                        reject(error);
                    }
                );
        })
    };

    function request() {
        return {type: usersConstants.LOGO_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Logo ajouté avec succès.")
        return {type: usersConstants.LOGO_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: usersConstants.LOGO_CREATE_FAILURE, error}
    }
}

function clearUser() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: usersConstants.USER_CLEAR_REQUEST}
    }
}
