/**
 *
 * Routes
 *
 * Array of all app routes to feed into react-router Route
 */
import {Role} from "../utils/roles";
import Constants from "../utils/constants";
import HomePage from "./components/HomePage";
import LoginPage from "./components/LoginPage";
import ForgotPasswordPage from "./components/login/ForgotPasswordPage";
import ResetPasswordPage from "./components/login/ResetPasswordPage";
import UsersPage from "./components/UsersPage";
import UsersNewOrEdit from "./components/UsersNewOrEdit";
import NotFound from "./components/layout/NotFound";
import PatrimoinePage from "./components/PatrimoinePage";
import PatrimoineImportPage from "./components/PatrimoineImportPage";
import PatrimoineDetailPage from "./components/PatrimoineDetailPage";
import ProblemsPage from "./components/ProblemsPage";
import ProblemsNewOrEdit from "./components/ProblemsNewOrEdit";
import FormsPage from "./components/FormsPage";
import FormsNewOrEdit from "./components/FormsNewOrEdit";
import LaveusesPage from "./components/LaveusesPage";
import LaveusesNewOrEdit from "./components/LaveusesNewOrEdit";
import CampaignsPage from "./components/CampaignsPage";
import CampaignsNewOrEdit from "./components/CampaignsNewOrEdit";
import InterventionsPage from "./components/InterventionsPage";
import SynthesePage from "./components/SynthesePage";
import PatrimoineNewOrEdit from "./components/PatrimoineNewOrEdit";
import LoginPageNew from "./components/LoginPageNew";
import CampaignUpdate from "./components/CampaignUpdate";
import DashboardPage from "./components/DashboardPage";
import InterventionsFormPage from "./components/InterventionsFormPage";
import PrestationsPage from "./components/PrestationsPage.js";
import PrestationNewOrEdit from "./components/PrestationNewOrEdit.js";
import ProblemsLaveusesNewOrEdit from "./components/ProblemsLaveusesNewOrEdit.js";
import LaveuseJournalPage from "./components/LaveuseJournalPage.js";
import RHDataPage from "./components/RHDataPage.js";
import RHDataEdit from "./components/RHDataEdit.js";
import RHSuiviHeures from "./components/RHSuiviHeures.js";
import RHFicheChantier from "./components/RHFicheChantier.js";
import RHPeriodePaiesPage from "./components/RHPeriodePaiesPage.js";
import RHPeriodePaieNewOrEdit from "./components/RHPeriodePaieNewOrEdit.js";
import FeuilleDeTempsNew from "./components/FeuilleDeTempsNew.js";

const Routes = [
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_HOME,
        roles: [],
        element: <HomePage/>,
    },
    {
        exact: true,
        protected: false,
        path: Constants.APP_ROUTE_LOGIN,
        roles: [],
        element: <LoginPageNew/>,
    },
    {
        exact: true,
        protected: false,
        path: Constants.APP_ROUTE_FORGOT_PASSWORD,
        roles: [],
        element: <ForgotPasswordPage/>,
    },
    {
        exact: true,
        protected: false,
        path: Constants.APP_ROUTE_RESET_PASSWORD,
        roles: [],
        element: <ResetPasswordPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_USERS_NEW,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <UsersNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Tableau de bord',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_DASHBOARD,
        roles: [Role.ROLE_EXPLOITANT],
        element: <DashboardPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_USERS_EDIT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <UsersNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Patrimoine',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_PATRIMOINE,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT, Role.ROLE_CLIENT],
        element: <PatrimoinePage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_PATRIMOINE_NEW,
        roles: [Role.ROLE_ADMIN],
        element: <PatrimoineNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_PATRIMOINE_EDIT,
        roles: [Role.ROLE_ADMIN],
        element: <PatrimoineNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_PATRIMOINE_IMPORT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <PatrimoineImportPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_PATRIMOINE_DETAIL,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT, Role.ROLE_CLIENT],
        element: <PatrimoineDetailPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Problèmes',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_ADMIN_PROBLEMS,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <ProblemsPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PROBLEMS_NEW,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <ProblemsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PROBLEMS_EDIT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <ProblemsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_NEW,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <ProblemsLaveusesNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_EDIT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <ProblemsLaveusesNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: '',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_ADMIN_PRESTATIONS,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <PrestationsPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PRESTATIONS_NEW,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <PrestationNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_PRESTATIONS_EDIT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <PrestationNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Formulaires',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_ADMIN_FORMS,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <FormsPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_FORMS_NEW,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <FormsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_FORMS_EDIT,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <FormsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_LAVEUSES_NEW,
        roles: [Role.ROLE_EXPLOITANT],
        element: <LaveusesNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_LAVEUSES_EDIT,
        roles: [Role.ROLE_EXPLOITANT],
        element: <LaveusesNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_CAMPAIGNS_NEW,
        roles: [Role.ROLE_EXPLOITANT],
        element: <CampaignsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_CAMPAIGNS_EDIT,
        roles: [Role.ROLE_EXPLOITANT],
        element: <CampaignsNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_CAMPAIGNS_UPDATE,
        roles: [Role.ROLE_EXPLOITANT],
        element: <CampaignUpdate/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_INTERVENTIONS,
        roles: [Role.ROLE_EXPLOITANT, Role.ROLE_ADMIN, Role.ROLE_CLIENT],
        element: <InterventionsPage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_INTERVENTIONS_UNIPATRIMOINE,
        roles: [Role.ROLE_EXPLOITANT, Role.ROLE_ADMIN, Role.ROLE_CLIENT],
        element: <InterventionsPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Synthèse',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_SYNTHESE,
        roles: [Role.ROLE_EXPLOITANT, Role.ROLE_ADMIN, Role.ROLE_CLIENT],
        element: <SynthesePage/>,
    },
    {
        exact: true,
        protected: true,
        path: Constants.APP_ROUTE_ADMIN_USERS,
        tiles: true,
        tilesLabel: 'Utilisateurs',
        tilesEnabled: true,
        roles: [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT],
        element: <UsersPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Tournées',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_ADMIN_CAMPAIGNS,
        roles: [Role.ROLE_EXPLOITANT],
        element: <CampaignsPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: true,
        tilesLabel: 'Laveuses',
        tilesEnabled: true,
        path: Constants.APP_ROUTE_ADMIN_LAVEUSES,
        roles: [Role.ROLE_EXPLOITANT],
        element: <LaveusesPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: '',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_ADMIN_LAVEUSES_JOURNAL,
        roles: [Role.ROLE_EXPLOITANT],
        element: <LaveuseJournalPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'InterventionForm',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_ADMIN_INTERVENTIONS_FORM,
        roles: [Role.ROLE_EXPLOITANT],
        element: <InterventionsFormPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'RHData',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_ADMIN_RH_DATA,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHDataPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'RHData',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_ADMIN_RH_DATA_EDIT,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHDataEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'RHData',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_SUIVIHEURES,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHSuiviHeures/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'FicheChantier',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_FICHECHANTIER,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHFicheChantier/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'Periode de paies',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_PERIODE_PAIES,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHPeriodePaiesPage/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'Periode de paies new/edit',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_PERIODE_PAIES_NEW,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHPeriodePaieNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'Periode de paies new/edit',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_PERIODE_PAIES_EDIT,
        roles: [Role.ROLE_EXPLOITANT],
        element: <RHPeriodePaieNewOrEdit/>,
    },
    {
        exact: true,
        protected: true,
        tiles: false,
        tilesLabel: 'Saisie des heures',
        tilesEnabled: false,
        path: Constants.APP_ROUTE_RH_FDT_NEW,
        roles: [Role.ROLE_EXPLOITANT],
        element: <FeuilleDeTempsNew/>,
    },
    {protected: false, path: '', element: <NotFound/>},
];

export default Routes;