import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import PageTitle from "./layout/PageTitle";
import {Grid} from "@mui/material";
import SchemaIcon from "@mui/icons-material/Schema";
import {rhdataActions} from "../../modules/rhdata/rhdata.actions.js";
import {useParams} from "react-router-dom";
import * as Yup from 'yup';
import {Formik} from "formik";
import RHDataField from "./rh/form/RHDataField.js";
import SubmitButton from "./layout/forms/SubmitButton.js";

const validationSchema = Yup.object().shape({
    tauxChargesPatronales: Yup.number()
        .typeError('Le taux de charges patronales doit être un nombre.')
        .required('Le taux de charges patronales est obligatoire.')
        .min(0, 'Le taux de charges patronales doit être supérieur ou égal à 0.')
        .max(100, 'Le taux de charges patronales ne peut pas dépasser 100.'), // Exemple : taux en pourcentage
    tauxHoraire: Yup.number()
        .typeError('Le taux horaire doit être un nombre.')
        .required('Le taux horaire est obligatoire.')
        .min(0, 'Le taux horaire doit être supérieur ou égal à 0.')
        .max(500, 'Le taux horaire ne peut pas dépasser 500.') // Exemple : limite max arbitraire
});

function RHDataEdit({dispatch, ...props}) {
    const params = useParams();
    const [RHDataId, setRHDataId] = useState(null)
    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setRHDataId(params.id);
        } else {
            setRHDataId(null);
        }
    }, [params]);

    useEffect(() => {
        if (RHDataId && (props.rhdata?.id !== parseInt(RHDataId)) && !props.loading && !props.error) {
            dispatch(rhdataActions.fetchRhdata(RHDataId));
        }
    }, [dispatch, RHDataId]);

    const initialValues = {
        id: props.rhdata?.id,
        tauxChargesPatronales: props.rhdata?.tauxChargesPatronales,
        tauxHoraire: props.rhdata?.tauxHoraire,
        forfaitPanierRepas: props.rhdata?.forfaitPanierRepas,
        forfaitDeplacement: props.rhdata?.forfaitDeplacement,
    }

    const handleSubmit = (values) => {
        dispatch(rhdataActions.updateRhdata(values))
    }

    return (
        <>
            <PageTitle title={"Modifier les données RH"} icon={<SchemaIcon/>}/>
            {props.loading &&
                <Loader/>
            }
            {!props.loading &&
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        handleSubmit(values)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <RHDataField
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    operateur={props.rhdata?.operateur}
                                />

                                <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                                    <Grid item xs={4}>
                                        <SubmitButton submitting={isSubmitting && !props.error}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        rhdata: state.rhdata.rhdata,
        loading: state.rhdata.loading,
        error: state.rhdata.error
    }
}

export default connect(mapStateToProps)(RHDataEdit);
