import * as React from 'react';
import withWidth from "@mui/material/Hidden/withWidth";
import {NavLink, useNavigate} from "react-router-dom";
import {
    Collapse,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid, IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography
} from "@mui/material";
import './AppDrawer.css';
import {Role} from "../../../utils/roles";
import Constants from "../../../utils/constants";
import {helper} from "../../../utils/helper";
import {
    Article, Dashboard,
    Engineering, ExpandLess, ExpandMore,
    HelpOutline,
    LocalShipping,
} from "@mui/icons-material";
import packageEnv from '../../../../package.json';
import ReactMarkdown from "react-markdown";
import {useEffect, useState} from "react";
import {ClipboardCheck} from '@styled-icons/bootstrap/ClipboardCheck'
import {Home} from '@styled-icons/feather/Home'
import {User} from '@styled-icons/feather/User'
import {Trash} from '@styled-icons/feather/Trash'
import {Speedometer2} from '@styled-icons/bootstrap/Speedometer2'
import {TaskListLtr} from '@styled-icons/fluentui-system-regular/TaskListLtr'
import {Gear} from '@styled-icons/evil/Gear'
import {Truck} from '@styled-icons/feather/Truck'
import {WorkOutline} from '@styled-icons/material-sharp/WorkOutline'
import {Payments} from '@styled-icons/material-outlined/Payments'
import WorkIcon from '@mui/icons-material/Work';
import WcIcon from '@mui/icons-material/Wc';
import SchemaIcon from '@mui/icons-material/Schema';
import styled from "styled-components";
import {ClockIcon} from "@mui/x-date-pickers";
import DescriptionIcon from '@mui/icons-material/Description';

const WhiteTruckIcon = styled(Truck)`color: white;
    width: 24px;`;
const WhiteClipboard = styled(ClipboardCheck)`color: white;
    width: 24px;`;
const WhiteHome = styled(Home)`color: white;
    width: 24px;`;
const WhiteUsers = styled(User)`color: white;
    width: 24px;`;
const WhiteBin = styled(Trash)`color: white;
    width: 24px;`;
const WhiteDashboard = styled(Speedometer2)`color: white;
    width: 24px;`;
const WhiteForms = styled(TaskListLtr)`color: white;
    width: 24px;`;
const WhiteProblems = styled(Gear)`color: white;
    width: 24px;`;
const WhiteLaveuses = styled(Truck)`color: white;
    width: 24px;`;
const WhitePrestations = styled(WorkOutline)`color: white;
    width: 24px;`;
const WhiteExploitation = styled(WorkIcon)`color: white;
    width: 24px;`;
const WhiteRH = styled(WcIcon)`color: white;
    width: 24px;`;
const WhiteRHData = styled(SchemaIcon)`color: white;
    width: 24px;`;
const WhiteSuiviHeures = styled(ClockIcon)`color: white;
    width: 24px;`;
const WhiteFicheChantier = styled(DescriptionIcon)`color: white;
    width: 24px;`;
const WhitePeriodePaies = styled(Payments)`color: white;
    width: 24px;`;

function AppDrawer(props) {
    const [changelogOpen, setChangelogOpen] = useState(false);
    const [changelog, setChangelog] = useState();
    const [exploitationSubmenuOpen, setExploitationSubmenuOpen] = useState(false);
    const [rhSubmenuOpen, setRhSubmenuOpen] = useState(false);
    const navigate = useNavigate();
    const currentUser = helper.getCurrentUser();

    useEffect(() => {
        import('../../../changelog.md')
            .then((module) => fetch(module.default))
            .then(response => response.text())
            .then(text => setChangelog(text))
            .catch(error => console.error('Erreur lors du chargement du changelog:', error));
    }, []);

    const getAppVersion = () => {
        let appVersion = `version du ${packageEnv.versionDate} ${packageEnv.version}`;
        if (import.meta.REACT_APP_ENV === "beta") {
            appVersion += "-BETA";
        }
        return appVersion;
    };

    const renderMenuItem = (to, IconComponent, text, roles = []) => {
        if (roles.length === 0 || roles.some(role => currentUser.roles.includes(role))) {
            return (
                <NavLink to={to} className={"drawerMenuNavlink"}>
                    <MenuItem>
                        <ListItemIcon>
                            <IconComponent/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography sx={{color: 'white', display: 'inline'}}>{text}</Typography>
                        </ListItemText>
                    </MenuItem>
                </NavLink>
            );
        }
        return null;
    };

    return (
        <Grid container direction="row" justifyContent="space-between" style={{height: '100vh', padding: '1rem'}}>
            <Grid item xs={12}>
                <img
                    className={"brandDrawer"}
                    src={"/images/logo_marquedeposee_blanc_vert_small.png"}
                    onClick={() => navigate(Constants.APP_ROUTE_HOME)}
                    alt="Logo"
                />
                <div className={import.meta.REACT_APP_ENV === "beta" ? "app-version version-beta" : "app-version"}>
                    {getAppVersion()}
                    <IconButton sx={{color: 'white'}} onClick={() => setChangelogOpen(true)}>
                        <HelpOutline/>
                    </IconButton>
                </div>
                <Grid item className={"pt-2 appDrawer-menu-container"}>
                    <MenuList>
                        {renderMenuItem(Constants.APP_ROUTE_HOME, WhiteHome, 'Accueil')}
                        {renderMenuItem(Constants.APP_ROUTE_DASHBOARD, WhiteDashboard, 'Tableau de bord', [Role.ROLE_EXPLOITANT])}
                        {renderMenuItem(Constants.APP_ROUTE_PATRIMOINE, WhiteBin, 'Patrimoines', [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT, Role.ROLE_CLIENT])}
                        {renderMenuItem(Constants.APP_ROUTE_SYNTHESE, WhiteClipboard, 'Synthèse', [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT, Role.ROLE_CLIENT])}

                        {/* Menu déroulant Exploitation */}
                        <MenuItem onClick={() => setExploitationSubmenuOpen(!exploitationSubmenuOpen)}
                                  sx={{height: '55px'}}>
                            <ListItemIcon>
                                <WhiteExploitation/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{color: 'white', display: 'inline'}}>Exploitation</Typography>
                            </ListItemText>
                            {exploitationSubmenuOpen ? <ExpandLess sx={{color: 'white'}}/> :
                                <ExpandMore sx={{color: 'white'}}/>}
                        </MenuItem>
                        <Collapse in={exploitationSubmenuOpen} timeout="auto" unmountOnExit
                                  sx={{paddingLeft: ".75rem"}}>
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_CAMPAIGNS, WhiteTruckIcon, 'Tournées de lavage', [Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_USERS, WhiteUsers, 'Gestion des utilisateurs', [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_FORMS, WhiteForms, 'Gestion des formulaires', [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_LAVEUSES, WhiteLaveuses, 'Gestion des laveuses', [Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_PROBLEMS, WhiteProblems, 'Gestion des problèmes', [Role.ROLE_ADMIN, Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_PRESTATIONS, WhitePrestations, 'Gestion des prestations', [Role.ROLE_EXPLOITANT])}
                        </Collapse>

                        <MenuItem onClick={() => setRhSubmenuOpen(!rhSubmenuOpen)} sx={{height: '55px'}}>
                            <ListItemIcon>
                                <WhiteRH/>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography sx={{color: 'white', display: 'inline'}}>Ressources Humaines</Typography>
                            </ListItemText>
                            {rhSubmenuOpen ? <ExpandLess sx={{color: 'white'}}/> :
                                <ExpandMore sx={{color: 'white'}}/>}
                        </MenuItem>
                        <Collapse in={rhSubmenuOpen} timeout="auto" unmountOnExit
                                  sx={{paddingLeft: ".75rem"}}>
                            {renderMenuItem(Constants.APP_ROUTE_ADMIN_RH_DATA, WhiteRHData, 'Données RH', [Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_RH_PERIODE_PAIES, WhitePeriodePaies, 'Période de paies', [Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_RH_SUIVIHEURES, WhiteSuiviHeures, 'Suivi des heures', [Role.ROLE_EXPLOITANT])}
                            {renderMenuItem(Constants.APP_ROUTE_RH_FICHECHANTIER, WhiteFicheChantier, 'Fiche suivi de chantier', [Role.ROLE_EXPLOITANT])}
                        </Collapse>

                    </MenuList>
                </Grid>
                <img src={"/images/poubelle-alpha.png"} alt={"poubelle"} className={"filigrane-poubelle"}/>
            </Grid>

            <Dialog
                open={changelogOpen}
                onClose={() => setChangelogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Notes de mise à jour
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ReactMarkdown>{changelog}</ReactMarkdown>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* Add any actions needed */}
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default withWidth()(AppDrawer);