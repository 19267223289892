import React from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Constants from "../../../../utils/constants";


function RHDataField({...props}) {
    const {values, errors, handleChange, handleBlur, operateur} = props;


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>
                    Données RH pour {operateur?.libelle}
                </Typography>
            </Grid>

            {/* TAUX CHARGES PATRONALES FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Taux Charges Patronales <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field name="tauxChargesPatronales">
                        {({ field, form }) => (
                            <TextField
                                {...field}
                                error={
                                    Boolean(form.errors.tauxChargesPatronales && form.touched.tauxChargesPatronales)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tauxChargesPatronales}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="tauxChargesPatronales"
                                label="Taux Charges Patronales (%)"
                                type="number"
                                helperText={
                                    form.errors.tauxChargesPatronales &&
                                    form.touched.tauxChargesPatronales &&
                                    String(form.errors.tauxChargesPatronales)
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* TAUX HORAIRE FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Taux Horaire <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field name="tauxHoraire">
                        {({ field, form }) => (
                            <TextField
                                {...field}
                                error={
                                    Boolean(form.errors.tauxHoraire && form.touched.tauxHoraire)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tauxHoraire}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="tauxHoraire"
                                label="Taux Horaire (€)"
                                type="number"
                                helperText={
                                    form.errors.tauxHoraire &&
                                    form.touched.tauxHoraire &&
                                    String(form.errors.tauxHoraire)
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* PANIER REPAS FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Forfait Panier Repas <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field name="forfaitPanierRepas">
                        {({ field, form }) => (
                            <TextField
                                {...field}
                                error={
                                    Boolean(form.errors.forfaitPanierRepas && form.touched.forfaitPanierRepas)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.forfaitPanierRepas}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="forfaitPanierRepas"
                                label="Forfait Panier Repas (€)"
                                type="number"
                                helperText={
                                    form.errors.forfaitPanierRepas &&
                                    form.touched.forfaitPanierRepas &&
                                    String(form.errors.forfaitPanierRepas)
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* FORFAIT DEPLACEMENT FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Forfait Déplacement <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field name="forfaitDeplacement">
                        {({ field, form }) => (
                            <TextField
                                {...field}
                                error={
                                    Boolean(form.errors.forfaitDeplacement && form.touched.forfaitDeplacement)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.forfaitDeplacement}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="forfaitDeplacement"
                                label="Forfait Déplacement (€)"
                                type="number"
                                helperText={
                                    form.errors.forfaitDeplacement &&
                                    form.touched.forfaitDeplacement &&
                                    String(form.errors.forfaitDeplacement)
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RHDataField;
