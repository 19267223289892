import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import {problemsActions} from "../../modules/problems/problems.actions";
import ProblemsTable from "./problems/ProblemsTable";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Button from "@mui/material/Button";
import {Truck} from "@styled-icons/feather/Truck";
import styled from "styled-components";
import {Gear} from "@styled-icons/evil/Gear";
import ProblemLaveusesTable from "./problems/ProblemLaveusesTable.js";

const ProblemsIcon = styled(Gear)`width: 24px;`;
const LaveuseIcon = styled(Truck)`width: 24px;`;

const getCategory = () => {
    const storedMode = window.localStorage.getItem("problems_category_view");
    return storedMode || Constants.PROBLEM_CATEGORY_CONTAINER;
};

function ProblemsPage({dispatch, ...props}) {
    const [category, setCategory] = useState(getCategory())

    useEffect(() => {
        if (!props.problems && !props.loading && !props.error) {
            dispatch(problemsActions.fetchProblems())
        }
    }, [dispatch, props.problems]);

    useEffect(() => {
        if (!props.problemsLaveuses && !props.loading && !props.error) {
            dispatch(problemsActions.fetchProblemLaveuses())
        }
    }, [dispatch, props.problemsLaveuses]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearProblems());
        };
    }, [dispatch]);

    const handleModeChange = (value) => {
        window.localStorage.setItem("problems_category_view", value);
        setCategory(value)
    }

    const getRoute = () => {
        return category === Constants.PROBLEM_CATEGORY_CONTAINER ? Constants.APP_ROUTE_ADMIN_PROBLEMS_NEW : Constants.APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_NEW
    }

    const handleDelete = (problem) => {
        dispatch(problemsActions.deleteProblem(problem.id));
    }
    const handleDeleteLaveuseProblem = (problem) => {
        dispatch(problemsActions.deleteProblemLaveuse(problem.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des problèmes"} icon={<Engineering/>}/>
            <ButtonCreate route={getRoute()} label="Ajouter un problème"/>

            <Grid container justifyContent={"center"} spacing={2} className={"mb-2"}>
                <Grid item>
                    <Button
                        variant={getCategory() === Constants.PROBLEM_CATEGORY_CONTAINER ? "contained" : "outlined"}
                        color="primary"
                        startIcon={<ProblemsIcon/>}
                        onClick={() => handleModeChange(Constants.PROBLEM_CATEGORY_CONTAINER)}
                    >
                        CONTENEURS
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={getCategory() === Constants.PROBLEM_CATEGORY_LAVEUSE ? "contained" : "outlined"}
                        color="primary"
                        startIcon={<LaveuseIcon/>}
                        onClick={() => handleModeChange(Constants.PROBLEM_CATEGORY_LAVEUSE)}
                    >
                       LAVEUSES
                    </Button>
                </Grid>
            </Grid>

            {props.loading &&
                <Loader/>
            }
            {!props.loading && category === Constants.PROBLEM_CATEGORY_CONTAINER &&
                <ProblemsTable problems={props.problems} onDelete={handleDelete}/>
            }
            {!props.loading && category === Constants.PROBLEM_CATEGORY_LAVEUSE &&
                <ProblemLaveusesTable problems={props.problemsLaveuses} onDelete={handleDeleteLaveuseProblem}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        problems: state.problems.problems,
        problemsLaveuses: state.problems.problemsLaveuse,
        loading: state.problems.loading,
        error: state.problems.error
    }
}

export default connect(mapStateToProps)(ProblemsPage);
