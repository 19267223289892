import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import {Grid, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {heuresActions} from "../../modules/heures/heures.actions.js";
import RHSuiviHeuresTable from "./rh/RHSuiviHeuresTable.js";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {frFR} from '@mui/x-date-pickers/locales';
import {fr} from 'date-fns/locale';
import {usersActions} from "../../modules/users/users.actions.js";
import FicheChantierFilters from "./rh/ficheChantier/FicheChantierFilters.js";
import {useParams} from "react-router-dom";
import moment from "moment-timezone";
import FicheChantierTable from "./rh/ficheChantier/FicheChantierTable.js";

function RHFicheChantier({dispatch, ...props}) {
    const params = useParams()
    const [selectedOperateur, setSelectedOperateur] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(null);

    useEffect(() => {
        if (!props.operateurs && !props.loading && !props.error) {
            dispatch(usersActions.fetchOperateurs("?RHFeatureEnabled=true"))
        }

        const operateurId = params.hasOwnProperty("operateurId") ? params.operateurId : null;

        if (operateurId) {
            setSelectedOperateur(operateurId);
            dispatch(heuresActions.fetchFicheChantier(selectedDateRange.startDate, selectedDateRange.endDate, operateurId));
        }

    }, [params]);

    const handleFilterChange = (filters) => {
        const { operateurId, startDate, endDate } = filters;

        if (
            operateurId === selectedOperateur &&
            startDate === selectedDateRange.startDate &&
            endDate === selectedDateRange.endDate
        ) {
            return; // Pas de changement, pas besoin de re-fetch.
        }

        setSelectedOperateur(operateurId);
        setSelectedDateRange({ startDate, endDate });
        console.log(`dispatch with ${startDate} - ${endDate}`)
        dispatch(heuresActions.fetchFicheChantier(startDate, endDate, operateurId));
    };


    // if (props.loading) {
    //     return <Loader/>
    // }

    console.log(props.ficheChantier)

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Fiche suivi de chantier
            </Typography>

            {props.operateurs &&
                <FicheChantierFilters operateurs={props.operateurs}
                                      initialOperateur={selectedOperateur}
                                      initialDate={selectedDateRange}
                                      onFilterChange={handleFilterChange}
                />
            }

            {props.ficheChantier &&
                <FicheChantierTable ficheChantier={props.ficheChantier} loading={props.loading} />
            }
        </>
    );
}

const mapStateToProps = function (state) {
    return {
        ficheChantier: state.heures.ficheChantier,
        operateurs: state.users.operateurs,
        loading: state.heures.loading || state.users.loading,
        error: state.heures.error || state.users.error
    }
}

export default connect(mapStateToProps)(RHFicheChantier);
