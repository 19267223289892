import {connect} from "react-redux";
import React, {useEffect} from "react";
import Loader from "./layout/loading/loader";
import {heuresActions} from "../../modules/heures/heures.actions.js";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle.js";
import {Payments} from "@styled-icons/material-outlined/Payments";
import ButtonCreate from "./layout/ButtonCreate.js";
import Constants from "../../utils/constants.js";
import RHPeriodePaiesTable from "./rh/RHPeriodePaiesTable.js";

function RHPeriodePaiesPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.periodePaies && !props.loading && !props.error) {
            dispatch(heuresActions.fetchPeriodePaies())
        }
    })


    if (props.loading) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <PageTitle title={"Gestion des périodes de paies"}/>
            <ButtonCreate route={Constants.APP_ROUTE_RH_PERIODE_PAIES_NEW} label="Ajouter une période"/>

            <RHPeriodePaiesTable periodePaies={props.periodePaies}/>
        </>
    )
}


const mapStateToProps = function (state) {
    return {
        periodePaies: state.heures.periodePaies,
        loading: state.heures.loading,
        error: state.heures.error
    }
}

export default connect(mapStateToProps)(RHPeriodePaiesPage);