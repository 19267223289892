import {quartixConstants} from './quartix.constants.js';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {data: {}, loading: false, error: null}

export function quartix(state = initialState, action) {
    switch (action.type) {
        case quartixConstants.ENGINE_TIMES_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case quartixConstants.ENGINE_TIMES_FETCH_SUCCESS:
            const { vehicleId, date, engineTimes } = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    ...state.data,
                    [vehicleId]: {
                        ...(state.data[vehicleId] || {}),
                        [date]: engineTimes,
                    },
                },
            };
        case quartixConstants.ENGINE_TIMES_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case quartixConstants.ENGINE_TIMES_CLEAR_REQUEST:
            return {
               ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
