import React from 'react';
import {
    Button,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import {format} from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {helper} from "../../../utils/helper";
import BadgeTypeLavage from "../layout/BadgeTypeLavage.js";

function InterventionTable({interventions, onPDFClick, ...props}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('dateEvenement');

    const [data, setData] = React.useState(interventions);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const sortedInterventions = interventions.sort((a, b) => {
            if (isAsc) {
                return a[property] < b[property] ? -1 : 1;
            } else {
                return a[property] > b[property] ? -1 : 1;
            }
        });

        setData(sortedInterventions);
    };

    // Table head cells
    const headCells = [
        {id: 'dateEvenement', label: "Date d'intervention"},
        {id: 'status', label: "Status"},
        {id: 'typeLavage', label: "Type de lavage"},
        {id: 'prestation', label: "Prestation"},
    ];


    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="patrimoine table" size="small">
                    <TableHeader headCells={headCells} order={order} orderBy={orderBy}
                                 onRequestSort={handleRequestSort}/>
                    <TableBody>
                        {interventions && interventions.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Il n'y a pas encore d'historique d'intervention"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {data && data.map((intervention) => {
                            return (
                                <React.Fragment key={intervention.id}>
                                    <TableRow hover>
                                        <TableCell>
                                            {helper.getFormattedDatetime(intervention.dateEvenement)}
                                        </TableCell>
                                        <TableCell>
                                            <Chip label={helper.getInterventionStatus(intervention)}
                                                  sx={helper.getInterventionStatusColor(intervention)}/>
                                        </TableCell>
                                        <TableCell>
                                            <BadgeTypeLavage type={intervention.typeLavage}/>
                                        </TableCell>
                                        <TableCell>
                                            {intervention.prestation}
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" size="small" variant={"contained"}
                                                    onClick={() => onPDFClick(intervention.id, intervention.dateEvenement)}
                                                    startIcon={<PictureAsPdfIcon/>}>
                                                Télécharger la fiche PDF
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default InterventionTable;
