export const heuresConstants = {
    HEURES_FETCH_REQUEST: "HEURES_FETCH_REQUEST",
    HEURES_FETCH_SUCCESS: "HEURES_FETCH_SUCCESS",
    HEURES_FETCH_FAILURE: "HEURES_FETCH_FAILURE",
    
    FEUILLEDETEMPS_UPDATE_REQUEST: "FEUILLEDETEMPS_UPDATE_REQUEST",
    FEUILLEDETEMPS_UPDATE_SUCCESS: "FEUILLEDETEMPS_UPDATE_SUCCESS",
    FEUILLEDETEMPS_UPDATE_FAILURE: "FEUILLEDETEMPS_UPDATE_FAILURE",
    
    FICHE_CHANTIER_FETCH_REQUEST: "FICHE_CHANTIER_FETCH_REQUEST",
    FICHE_CHANTIER_FETCH_SUCCESS: "FICHE_CHANTIER_FETCH_SUCCESS",
    FICHE_CHANTIER_FETCH_FAILURE: "FICHE_CHANTIER_FETCH_FAILURE",
    
    EXPORT_SUIVI_HEURES_REQUEST: "EXPORT_SUIVI_HEURES_REQUEST",
    EXPORT_SUIVI_HEURES_SUCCESS: "EXPORT_SUIVI_HEURES_SUCCESS",
    EXPORT_SUIVI_HEURES_FAILURE: "EXPORT_SUIVI_HEURES_FAILURE",

    PERIODE_PAIES_FETCH_REQUEST: "PERIODE_PAIES_FETCH_REQUEST",
    PERIODE_PAIES_FETCH_SUCCESS: "PERIODE_PAIES_FETCH_SUCCESS",
    PERIODE_PAIES_FETCH_FAILURE: "PERIODE_PAIES_FETCH_FAILURE",

    PERIODE_PAIES_FETCH_ONE_REQUEST: "PERIODE_PAIES_FETCH_ONE_REQUEST",
    PERIODE_PAIES_FETCH_ONE_SUCCESS: "PERIODE_PAIES_FETCH_ONE_SUCCESS",
    PERIODE_PAIES_FETCH_ONE_FAILURE: "PERIODE_PAIES_FETCH_ONE_FAILURE",

    PERIODE_PAIES_CREATE_REQUEST: "PERIODE_PAIES_CREATE_REQUEST",
    PERIODE_PAIES_CREATE_SUCCESS: "PERIODE_PAIES_CREATE_SUCCESS",
    PERIODE_PAIES_CREATE_FAILURE: "PERIODE_PAIES_CREATE_FAILURE",

    PERIODE_PAIES_UPDATE_REQUEST: "PERIODE_PAIES_UPDATE_REQUEST",
    PERIODE_PAIES_UPDATE_SUCCESS: "PERIODE_PAIES_UPDATE_SUCCESS",
    PERIODE_PAIES_UPDATE_FAILURE: "PERIODE_PAIES_UPDATE_FAILURE",

    PERIODE_PAIES_DELETE_REQUEST: "PERIODE_PAIES_DELETE_REQUEST",
    PERIODE_PAIES_DELETE_SUCCESS: "PERIODE_PAIES_DELETE_SUCCESS",
    PERIODE_PAIES_DELETE_FAILURE: "PERIODE_PAIES_DELETE_FAILURE",

    POSTE_CREATE_REQUEST: "POSTE_CREATE_REQUEST",
    POSTE_CREATE_SUCCESS: "POSTE_CREATE_SUCCESS",
    POSTE_CREATE_FAILURE: "POSTE_CREATE_FAILURE",


    HEURES_CLEAR_REQUEST: "HEURES_CLEAR_REQUEST",

};
