import React, { useEffect, useState } from "react";
import { Grid, TextField, MenuItem, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

function FicheChantierFilters({ onFilterChange, operateurs, initialOperateur, initialDate }) {
    const [selectedOperateur, setSelectedOperateur] = useState(initialOperateur || "");
    const [selectedDate, setSelectedDate] = useState(
        initialDate?.startDate ? moment(initialDate.startDate).toDate() : moment().toDate()
    );



    const handleApplyFilters = () => {
        if (onFilterChange) {
            const startDate = moment(selectedDate).startOf("week").add(1, "day");
            const endDate = moment(selectedDate).endOf("week").add(1, "day");
            onFilterChange({
                operateurId: selectedOperateur,
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
            });
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {/* Sélecteur d'opérateur */}
            <Grid item xs={12} sm={3}>
                <TextField
                    select
                    fullWidth
                    label="Sélectionnez un opérateur"
                    value={selectedOperateur}
                    onChange={(e) => setSelectedOperateur(e.target.value)}
                >
                    {operateurs.map((operateur) => (
                        <MenuItem key={operateur.id} value={operateur.id}>
                            {operateur.libelle}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Sélecteur de date */}
            <Grid item xs={12} sm={3}>
                <DatePicker
                    views={["year", "month", "day"]}
                    displayWeekNumber={true}
                    label="Sélectionnez une date dans la semaine"
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}

                />
            </Grid>

            {/* Bouton d'application des filtres */}
            <Grid item xs={3}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!selectedOperateur || !selectedDate}
                    onClick={handleApplyFilters}
                >
                    Appliquer les filtres
                </Button>
            </Grid>
        </Grid>
    );
}

export default FicheChantierFilters;