import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Chip, FormControlLabel,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";
import {helper} from "../../../utils/helper.js";

function RHPeriodePaiesTable({periodePaies, ...props}) {
    if (!periodePaies) return;
    const navigate = useNavigate()
    const [showPast, setShowPast] = useState(false);

    // Date du jour (pour savoir ce qui est "terminé" ou non)
    const now = new Date();

    const sorted = [...periodePaies].sort((a, b) => {
        // Convertit a.startDate en un objet Date
        const da = new Date(a.startDate);
        const db = new Date(b.startDate);
        return da - db;
    });

    const filtered = showPast
        ? sorted
        : sorted.filter(p => {
            const end = new Date(p.endDate);
            return end >= now; // On garde seulement si endDate >= aujourd’hui
        });

    // Table head cells
    const headCells = [
        {id: 'libelle', label: 'Libellé'},
        {id: 'startDate', label: 'Date de début'},
        {id: 'endDate', label: 'Date de fin'},
    ];


    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_RH_PERIODE_PAIES_EDIT.replace(':id', id))
    }

    return (
        <React.Fragment>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showPast}
                        onChange={e => setShowPast(e.target.checked)}
                        color="primary"
                    />
                }
                label="Afficher les périodes passées"
            />
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="problems table" size="small">
                    <TableHeader headCells={headCells}/>
                    <TableBody>
                        {periodePaies && periodePaies.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucune données enregistrée"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {filtered && filtered.map((periodePaie, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TableRow hover
                                              onClick={() => handleEditClick(periodePaie.id)}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            {periodePaie.libelle}
                                        </TableCell>
                                        <TableCell>
                                            {helper.formatDate(periodePaie.startDate)}
                                        </TableCell>
                                        <TableCell>
                                            {helper.formatDate(periodePaie.endDate)}
                                        </TableCell>

                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(periodePaie.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                        Editer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default RHPeriodePaiesTable;
