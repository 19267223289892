import React, {useState} from 'react';
import {Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";

function ProblemLaveusesTable({problems, ...props}) {
    const navigate = useNavigate()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('libelle');
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [currentProblem, setCurrentProblem] = useState(null);
    let currentType = null;

    // Table head cells
    const headCells = [
        {id: 'libelle', label: 'Libellé'},
        {id: 'category', label: 'Catégorie'},
        {id: 'importance', label: 'Importance'},
    ];


    const openDeleteConfirmModal = (p) => {
        setCurrentProblem(p)
        setLabel("Etes vous sûr de vouloir supprimer le problème : ")
        setObjectToDelete(p.libelle)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentProblem(null)
    };

    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_PROBLEMS_LAVEUSES_EDIT.replace(':id', id))
    }

    const handleDelete = () => {
        props.onDelete(currentProblem)
        handleClose()
    }

    const getImportanceLibelle = (importance) => {
        const data = Constants.PROBLEMS_LAVEUSE_IMPORTANCE.find(
            (item) => item.code == importance
        )

        return data?.libelle ?? 'erreur'
    }

    const getImportanceColor = (importance) => {
        const data = Constants.PROBLEMS_LAVEUSE_IMPORTANCE.find(
            (item) => item.code == importance
        )

        return data?.color ?? '#000000'
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getItemComparator = (item, comparator) => {
        return item[comparator];
    }


    // Fonction pour trier automatiquement par "Importance"
    const sortedData = () => {
        return problems.sort((a, b) => {
            return (order === 'asc')
                ? a.category.localeCompare(b.category)
                : b.category.localeCompare(a.category);
        });
    }


    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="problems table" size="small">
                    <TableHeader headCells={headCells}/>
                    <TableBody>
                        {problems && problems.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucun problème enregistré"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {problems && sortedData().map((problem) => {
                            let addBorder = false;
                            // if (problem.category !== currentType) {
                            //     addBorder = true
                            //     currentType = problem.category
                            // }
                            return (
                                <React.Fragment key={problem.id}>
                                    <TableRow hover className={addBorder ? 'problemTable-border' : ''}
                                              onClick={() => handleEditClick(problem.id)}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            {problem.libelle}
                                        </TableCell>
                                        <TableCell>
                                            {problem.category}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={getImportanceLibelle(problem.importance)}
                                                style={{
                                                    backgroundColor: getImportanceColor(problem.importance),
                                                    color: 'white'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(problem.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                        Editer
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button color="error" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDeleteConfirmModal(problem)
                                                            }}
                                                            startIcon={<CloseOutlinedIcon/>}>
                                                        Supprimer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentProblem} onDelete={handleDelete}/>
        </React.Fragment>
    )
}

export default ProblemLaveusesTable;
