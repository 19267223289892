import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const rhdataService = {
    fetchRhdatas,
    fetchRhdata,
    deleteRhdata,
    createRhdata,
    updateRhdata,
};

function fetchRhdatas() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_RHDATAS, requestOptions)
        .then(handleResponse)
        .then(prestations => {
            return prestations["hydra:member"]
        });
}

function fetchRhdata(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_RHDATA.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(prestation => {
            return prestation
        });
}

function createRhdata(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_RHDATAS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateRhdata(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_RHDATA.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteRhdata(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_RHDATA.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
