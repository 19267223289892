import React from 'react';
import {Field} from 'formik';
import {
    FormControl,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment-timezone";

function PeriodePaieDataField({values, errors, handleChange, handleBlur}) {

    // pour gérer la conversion "string" <-> Date
    const startDateValue = values.startDate ? new Date(values.startDate) : null;
    const endDateValue = values.endDate ? new Date(values.endDate) : null;

    return (
        <Grid container spacing={1} className={"sectionContainer"}>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.libelle}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.libelle && form.touched.libelle)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="libelle"
                                label="libelle"
                                autoFocus
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/* START DATE FIELD */}
            <Grid item container alignItems="center" className="mt-1 mb-1">
                <Grid item xs={3}>
                    <Typography>Date de début <span className="asterix">*</span></Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"startDate"}
                        value={values.startDate}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.startDate}>
                                <DatePicker
                                    label="Date de début"
                                    value={startDateValue}
                                    onChange={(newValue) => {
                                        // on stocke en string 'yyyy-MM-dd'
                                        if (newValue) {
                                            const iso = moment.parseZone(newValue).startOf('day').format('YYYY-MM-DD');
                                            form.setFieldValue('startDate', iso);
                                        } else {
                                            form.setFieldValue('startDate', '');
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            error={Boolean(form.errors.startDate && form.touched.startDate)}
                                            helperText={form.errors.startDate && form.touched.startDate ? form.errors.startDate : ''}
                                        />
                                    )}
                                />
                            </FormControl>
                        )}
                    </Field>

                </Grid>
            </Grid>

            {/* END DATE FIELD */}
            <Grid item container alignItems="center" className="mt-1 mb-1">
                <Grid item xs={3}>
                    <Typography>Date de fin <span className="asterix">*</span></Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"endDate"}
                        value={values.endDate}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.endDate}>
                                <DatePicker
                                    label="Date de fin"
                                    value={endDateValue}
                                    onChange={(newValue) => {
                                        // on stocke en string 'yyyy-MM-dd'
                                        if (newValue) {
                                            const iso = moment.parseZone(newValue).startOf('day').format('YYYY-MM-DD');
                                            form.setFieldValue('endDate', iso);
                                        } else {
                                            form.setFieldValue('endDate', '');
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            error={Boolean(form.errors.endDate && form.touched.endDate)}
                                            helperText={form.errors.endDate && form.touched.endDate ? form.errors.endDate : ''}
                                        />
                                    )}
                                />
                            </FormControl>
                        )}
                    </Field>

                </Grid>
            </Grid>

        </Grid>
    );
}

export default PeriodePaieDataField;
