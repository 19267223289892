import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import './UsersNewOrEdit.css'
import {array, date, object, string} from 'yup';
import {Box, CircularProgress, Step, StepLabel, Stepper} from "@mui/material";
import {useParams} from "react-router-dom";
import {campaignsActions} from "../../modules/campaigns/campaigns.actions";
import Button from "@mui/material/Button";
import CampaignDataField from "./campaigns/form/CampaignDataField";
import {usersActions} from "../../modules/users/users.actions";
import CampaignPatrimoineField from "./campaigns/form/CampaignPatrimoineField";
import CampaignOperateursField from "./campaigns/form/CampaignOperateursField";
import CampaignSummary from "./campaigns/form/CampaignSummary";
import {TravelExplore} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {RRuleHelper} from "../../utils/RRuleHelper";
import {format} from "date-fns";
import {rrulestr} from "rrule";
import CampaignOrderField from "./campaigns/form/CampaignOrderField";
import SubmitButton from "./layout/forms/SubmitButton";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import {conteneursActions} from "../../modules/conteneurs/conteneurs.actions.js";
import {prestationsActions} from "../../modules/prestations/prestations.actions.js";


const campaignValidationSchema = object({
    libelle: string()
        .max(255, 'Le libellé ne doit pas dépasser 255 caractères')
        .required('Le libellé est requis'),
    dateDebut: date()
        .required('La date de début est requise'),
    dateFin: date()
        .required('La date de fin est requise'),
    conteneurs: array().nullable(),
    chauffeur: string().required('Au moins un chauffeur doit être sélectionné'),
    client: string().nullable().required('Le client est requis'),
    formulaire: string().required('Le formulaire est requis'),
    patrimoine: string().required('Le patrimoine est requis'),
});

const steps = ["Détail de la tournée", "Sélection du patrimoine", "Ordre de la tournée", "Sélection des opérateurs"]

function CampaignsNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [campaignId, setCampaignId] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedClient, setSelectedClient] = useState(null);


    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setCampaignId(params.id);
        } else {
            setMode("create");
            setCampaignId(null);
        }
    }, [params]);

    useEffect(() => {
        if (campaignId && (props.campaign?.id !== parseInt(campaignId)) && !props.loading && !props.error) {
            dispatch(campaignsActions.fetchCampaign(campaignId));
        }
    }, [props, dispatch, campaignId]);


    useEffect(() => {
        if (!props.clients && !props.loading && !props.error) {
            dispatch(usersActions.fetchClients());
        }
    }, [dispatch, props.clients, props.error, props.loading]);

    useEffect(() => {
        if (!props.users && !props.loading && !props.error) {
            dispatch(usersActions.fetchUsers());
        }
    }, [dispatch, props.users, props.error, props.loading]);

    useEffect(() => {
        if (!props.prestations && !props.loading && !props.error) {
            dispatch(prestationsActions.fetchPrestations());
        }
    }, [dispatch, props.prestations, props.error, props.loading]);

    const getInitialValues = () => {
        const currentDate = new Date();
        const tomorrowDate = new Date();
        tomorrowDate.setDate(currentDate.getDate() + 1);

        // Si une tournée est fournie et qu'elle a une chaîne RRULE, parse
        let parsedRRule = null;
        if (props.campaign && props.campaign.rrule) {
            parsedRRule = rrulestr(props.campaign.rrule);
        }

        return {
            id: props.campaign?.id ?? null,
            libelle: props.campaign?.libelle ?? '',
            dateDebut: props.campaign?.dateDebut ?? format(currentDate, 'yyyy-MM-dd'),
            dateFin: props.campaign?.dateFin ?? format(tomorrowDate, 'yyyy-MM-dd'), //demain,
            freq: parsedRRule ? RRuleHelper.FREQMAP[parsedRRule.options.freq] : RRuleHelper.FREQ[1].id,
            interval: parsedRRule ? parsedRRule.options.interval : 1,
            byDay: parsedRRule && parsedRRule.options.byweekday
                ? parsedRRule.options.byweekday.map(d => RRuleHelper.DAYMAP[d]) : [],
            byMonthDay: parsedRRule ? parsedRRule.options.bymonthday : [],
            byMonth: parsedRRule ? RRuleHelper.MONTHMAP[parsedRRule.options.bymonth] : "",
            bySetPos: parsedRRule ? parsedRRule.options.bysetpos : RRuleHelper.SETPOS[0].id,
            recurrenceType: props.campaign?.recurrenceType ?? RRuleHelper.RECURRINGTYPE.DAYOFMONTH,
            conteneurs: props.campaign?.conteneurs.map((c) => c["@id"]) ?? [],
            chauffeur: props.campaign?.chauffeur['@id'] || "",
            equipier: props.campaign?.equipier?.['@id'] || "",
            prestation: props.campaign?.prestation?.['@id'] || '',
            client: props.campaign?.client['@id'] ?? '',
            formulaire: props.campaign?.formulaire['@id'] ?? '',
            patrimoine: props.campaign?.patrimoine['@id'] ?? '',
            ordre: props.campaign?.ordre ?? [],
        };
    }


    const initialValues = getInitialValues();

    useEffect(() => {
        if (selectedClient) {
            getPatrimoinesForClient(selectedClient)
        }
    }, [selectedClient]);

    useEffect(() => {
        // Si en mode "edit" et le client est défini, mettez à jour le state selectedClient
        if (mode === "edit" && initialValues.client) {
            setSelectedClient(initialValues.client);
        }
    }, [initialValues, mode]);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const hasStepErrors = (index, errors) => {
        if (index === 0) {
            return (!!errors?.libelle || !!errors?.client || !!errors?.formulaire)
        } else if (index === 1) {
            return (!!errors?.patrimoine || !!errors?.conteneurs)
        } else if (index === 3) {
            return (!!errors?.operateurs)
        }

        return false;
    }

    const getPatrimoinesForClient = (clientId) => {
        if (clientId.indexOf("api/") > -1) {
            let parts = clientId.split("/");
            clientId = parts[parts.length - 1];
        }
        dispatch(patrimoinesActions.fetchPatrimoinesForClient(clientId))
    }

    const getOperateurs = () => {
        if (props.users) {
            // Une tournée se créer en tant qu'exploitant.
            // Ici nous avons donc l'exploitant connecté dans props.users
            return props.users[0]?.operateurs
        }
        return []
    }

    const getValuesForSummary = (values) => {
        let populatedValues = {};
        populatedValues.libelle = values.libelle;
        populatedValues.dateDebut = values.dateDebut;
        populatedValues.dateFin = values.dateFin;
        populatedValues.conteneurs = values.conteneurs;
        populatedValues.prestation = props.prestations.find((presta) => presta['@id'] === values.prestation);
        populatedValues.client = props.clients.find((client) => client['@id'] === values.client);
        populatedValues.formulaire = populatedValues.client?.forms.find((form) => form['@id'] === values.formulaire);
        populatedValues.patrimoine = populatedValues.client?.patrimoines.find((patrimoine) => patrimoine['@id'] === values.patrimoine);
        const operateurs = getOperateurs();

        populatedValues.chauffeur = operateurs.find((o) => o['@id'] === values.chauffeur);
        if (values.equipier) {
            populatedValues.equipier = operateurs.find((o) => o['@id'] === values.equipier);
        }

        populatedValues.rrule = RRuleHelper.buildRRuleString(values);

        return populatedValues;
    }

    const handleSubmit = (values) => {
        values.rrule = RRuleHelper.buildRRuleString(values);
        if (values.equipier == "") {
            delete values.equipier
        }
        if (mode === "create") {
            dispatch(campaignsActions.createCampaign(values));
        } else {
            if (values.conteneurs.length > 0) {
                if (values.conteneurs[0].hasOwnProperty("nomenclature")) {
                    //We are passing full object but we need IRI only
                    values.conteneurs = values.conteneurs.map((conteneur) => conteneur['@id']).reduce((acc, id) => {
                        acc.push(id);
                        return acc;
                    }, []);
                }
            }
            if (values.equipier == "") {
                delete values.equipier
            }
            console.log("UPDATING WITH VALUES " + JSON.stringify(values))
            dispatch(campaignsActions.updateCampaign(values, values.id));
        }
    }


    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouvelle tournée" : "Modifier la tournée"}
                       icon={<TravelExplore/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={false}
                validationSchema={campaignValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true)
                    handleSubmit(values)
                    setTimeout(() => {
                        setSubmitting(false)
                    }, 1500)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit} className={"mb-2"}>
                        <Stepper activeStep={activeStep} alternativeLabel sx={{mb: 2}}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}
                                                   error={hasStepErrors(index, errors)}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <CampaignSummary values={getValuesForSummary(values)} errors={errors}/>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        disabled={activeStep === 0}
                                        onClick={handleBackStep}
                                        sx={{mr: 1, color: 'white'}}
                                    >
                                        Retour
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    <SubmitButton submitting={isSubmitting} label={"Enregistrer la tournée"}
                                                  color={"secondary"}/>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {activeStep === 0 &&
                                    <CampaignDataField values={values} errors={errors} handleChange={handleChange}
                                                       onClientSelected={setSelectedClient}
                                                       prestations={props.prestations}
                                                       handleBlur={handleBlur} clients={props.clients}/>
                                }
                                {activeStep === 1 && props.patrimoines &&
                                    <CampaignPatrimoineField values={values} errors={errors} handleChange={handleChange}
                                                             handleBlur={handleBlur}
                                                             patrimoines={props.patrimoines}/>
                                }
                                {activeStep === 2 &&
                                    <CampaignOrderField values={values} errors={errors} handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        patrimoines={props.patrimoines}/>
                                }
                                {activeStep === 3 &&
                                    <CampaignOperateursField values={values} errors={errors} handleChange={handleChange}
                                                             handleBlur={handleBlur}
                                                             operateurs={getOperateurs()}/>
                                }
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        disabled={activeStep === 0}
                                        onClick={handleBackStep}
                                        sx={{mr: 1}}
                                    >
                                        Retour
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>


                                    <Button onClick={handleNextStep}
                                            variant={"contained"}
                                            color={"secondary"}
                                            sx={{color: 'white'}}>
                                        {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        campaign: state.campaigns.campaign,
        clients: state.users.clients,
        prestations: state.prestations.prestations,
        patrimoines: state.patrimoines.patrimoines,
        users: state.users.users,
        loading: state.campaigns.loading || state.users.loading,
        patrimoineLoading: state.patrimoines.loading,
        error: state.campaigns.error || state.users.error || state.patrimoines.error,
    }
}

export default connect(mapStateToProps)(CampaignsNewOrEdit);
