import {problemsConstants} from "./problems.constants";
import {problemsService} from "./problems.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const problemsActions = {
    fetchProblems,
    fetchProblem,
    deleteProblem,
    createProblem,
    updateProblem,
    fetchProblemLaveuses,
    fetchProblemLaveuse,
    deleteProblemLaveuse,
    createProblemLaveuse,
    updateProblemLaveuse,
    clearProblems
};

function fetchProblems(pagination = false) {
    return dispatch => {
        dispatch(request());

        problemsService.fetchProblems(pagination)
            .then(
                problems => {
                    let res = problems;
                    try {
                        res = problems.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_FETCH_REQUEST}
    }

    function success(problems) {
        return {type: problemsConstants.PROBLEMS_FETCH_SUCCESS, problems}
    }

    function failure(error) {
        return {type: problemsConstants.PROBLEMS_FETCH_FAILURE, error}
    }
}

function fetchProblem(id) {
    return dispatch => {
        dispatch(request());
        problemsService.fetchProblem(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_FETCH_ONE_REQUEST}
    }

    function success(problem) {
        return {type: problemsConstants.PROBLEMS_FETCH_ONE_SUCCESS, problem}
    }

    function failure(error) {
        return {type: problemsConstants.PROBLEMS_FETCH_ONE_FAILURE, error}
    }
}

function deleteProblem(id, navigate) {
    return dispatch => {
        dispatch(request());

        problemsService.deleteProblem(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: problemsConstants.PROBLEMS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été supprimé.")
        return {type: problemsConstants.PROBLEMS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_DELETE_FAILURE, error}
    }
}

function createProblem(values, navigate) {
    return dispatch => {
        dispatch(request());

        problemsService.createProblem(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été créé.")
        return {type: problemsConstants.PROBLEMS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_CREATE_FAILURE, error}
    }
}

function updateProblem(values, navigate) {
    return dispatch => {
        dispatch(request());
        problemsService.updateProblem(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été modifié.")
        return {type: problemsConstants.PROBLEMS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_UPDATE_FAILURE, error}
    }
}

function fetchProblemLaveuses(pagination = false) {
    return dispatch => {
        dispatch(request());

        problemsService.fetchProblemLaveuses(pagination)
            .then(
                problems => {
                    let res = problems;
                    try {
                        res = problems.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_REQUEST}
    }

    function success(problems) {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_SUCCESS, problems}
    }

    function failure(error) {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_FAILURE, error}
    }
}

function fetchProblemLaveuse(id) {
    return dispatch => {
        dispatch(request());
        problemsService.fetchProblemLaveuse(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_ONE_REQUEST}
    }

    function success(problem) {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_ONE_SUCCESS, problem}
    }

    function failure(error) {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_FETCH_ONE_FAILURE, error}
    }
}

function deleteProblemLaveuse(id, navigate) {
    return dispatch => {
        dispatch(request());

        problemsService.deleteProblemLaveuse(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été supprimé.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_DELETE_FAILURE, error}
    }
}

function createProblemLaveuse(values, navigate) {
    return dispatch => {
        dispatch(request());

        problemsService.createProblemLaveuse(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été créé.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_CREATE_FAILURE, error}
    }
}

function updateProblemLaveuse(values, navigate) {
    return dispatch => {
        dispatch(request());
        problemsService.updateProblemLaveuse(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PROBLEMS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_LAVEUSE_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le problème a bien été modifié.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: problemsConstants.PROBLEMS_LAVEUSE_UPDATE_FAILURE, error}
    }
}

function clearProblems() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: problemsConstants.PROBLEMS_CLEAR_REQUEST}
    }
}
