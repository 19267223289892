import {rhdataConstants} from './rhdata.constants.js';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {rhdatas: null, rhdata: null, loading: false, error: null}

export function rhdata(state = initialState, action) {
    switch (action.type) {
        case rhdataConstants.RHDATA_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case rhdataConstants.RHDATA_FETCH_SUCCESS:
            return {
                ...state,
                rhdatas: action.rhdatas,
                rhdata: null,
                loading: false,
                error: null
            };
        case rhdataConstants.RHDATA_FETCH_ONE_REQUEST:
            return {
                ...state,
                rhdata: null,
                loading: true,
                error: null
            };
        case rhdataConstants.RHDATA_FETCH_ONE_SUCCESS:
            return {
                ...state,
                rhdata: action.rhdata,
                rhdatas: null,
                loading: false,
                error: null
            };
        case rhdataConstants.RHDATA_FETCH_ONE_FAILURE:
            return {
                ...state,
                rhdata: null,
                loading: false,
                error: action.error
            };
        case rhdataConstants.RHDATA_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case rhdataConstants.RHDATA_DELETE_SUCCESS:
        case rhdataConstants.RHDATA_CREATE_SUCCESS:
        case rhdataConstants.RHDATA_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                rhdatas: null,
                rhdata: null
            };
        case rhdataConstants.RHDATA_FETCH_FAILURE:
        case rhdataConstants.RHDATA_CREATE_FAILURE:
        case rhdataConstants.RHDATA_UPDATE_FAILURE:
        case rhdataConstants.RHDATA_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case rhdataConstants.RHDATA_CLEAR_REQUEST:
            return {
               ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
