import React, {useState} from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Constants from "../../../../utils/constants";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import {format, parseISO} from "date-fns";


function FeuilleDeTempsDataField({...props}) {
    const {values, errors, handleChange, handleBlur, operateurs} = props;

    const [operateursData, setOperateursData] = useState(operateurs?.sort((a, b) => a.libelle.localeCompare(b.libelle)));


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>
                    Saisie des heures
                </Typography>
            </Grid>

            {/* OPERATEUR FIELD */}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    <Typography>Sélectionner l'opérateur *</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"operateur"}
                        value={values.operateur}>
                        {({form}) => (
                            <FormControl fullWidth error={Boolean(errors.operateur)}>
                                <InputLabel id="label-client">Sélectionner l'opérateur :</InputLabel>
                                <Select
                                    labelId="label-operateur"
                                    label="Sélectionner l'operateur :"
                                    variant="outlined"
                                    name="operateur"
                                    value={values.operateur}
                                    onChange={(e) => {
                                        form.setFieldValue(
                                            "operateur",
                                            e.target.value
                                        );
                                    }}
                                >
                                    {operateursData && operateursData.map((o) => {
                                        return (
                                            <MenuItem key={o.id} value={o.id}>
                                                {o.libelle ?? o.email}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {errors.operateur &&
                                    String(errors.operateur) &&
                                    <FormHelperText error={true}>{errors.operateur}</FormHelperText>}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*HEURE DEBUT FIELD*/}
            <Grid item container alignItems={"center"} className={'mt-1 mb-1'}>
                <Grid item xs={3}>
                    Heure de début <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"heureDebut"}
                        value={values.heureDebut}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.heureDebut}>
                                <DateTimePicker value={parseISO(values.heureDebut)}
                                                onChange={(value) => {
                                                    form.setFieldValue(field.name, format(value, 'yyyy-MM-dd HH:mm:ss'));
                                                }}
                                />
                                {errors.heureDebut && (
                                    <FormHelperText error={true}>{errors.heureDebut}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*TEMPS DE PAUSE FIELD*/}
            <Grid item container alignItems={"center"} className={'mt-1 mb-1'}>
                <Grid item xs={3}>
                    Temps de pause
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"heureDebut"}
                        value={values.heureDebut}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.tempsPauseMinutes)}>
                                <InputLabel id="label-pause">Indiquer le temps de pause :</InputLabel>
                                <Select
                                    labelId="label-pause"
                                    value={values.tempsPauseMinutes}
                                    onChange={(e) => {
                                        form.setFieldValue('tempsPauseMinutes', e.target.value);
                                    }}
                                >
                                    {Constants.TEMPS_PAUSE_MINUTES_DATA.map((p) => (
                                        <MenuItem key={p.value} value={p.value}>
                                            {p.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.tempsPauseMinutes && (
                                    <FormHelperText error={true}>{errors.tempsPauseMinutes}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>


            {/*HEURE FIN FIELD*/}
            <Grid item container alignItems={"center"} className={'mt-1 mb-1'}>
                <Grid item xs={3}>
                    Heure de fin <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name={"heureFin"}
                        errors={errors.heureFin}
                        value={values.heureFin}>
                        {({field, form}) => (
                            <FormControl fullWidth
                                         errors={errors.heureFin}>
                                <DateTimePicker value={parseISO(values.heureFin)} errors={errors.heureFin}
                                                onChange={(value) => {
                                                    form.setFieldValue(field.name, format(value, 'yyyy-MM-dd HH:mm:ss'));
                                                }}
                                />
                                {errors.heureFin && (
                                    <FormHelperText error={true}>{errors.heureFin}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default FeuilleDeTempsDataField;
