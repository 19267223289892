import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import './UsersNewOrEdit.css'
import {number, object, string} from 'yup';
import {Container, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubmitButton from "./layout/forms/SubmitButton";
import {problemsActions} from "../../modules/problems/problems.actions";
import ProblemDataField from "./problems/form/ProblemDataField";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import Constants from "../../utils/constants";
import ProblemLaveuseDataField from "./problems/form/ProblemLaveuseDataField.js";


const problemValidationSchema = object({
    libelle: string()
        .required("Le libellé est requis")
        .max(255, 'Le libellé ne doit pas dépasser 255 caractères'),
    importance: number().required("Le degré d'importance est requis"),
});

function ProblemsLaveusesNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [problemId, setProblemId] = useState(null);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setProblemId(params.id);
        } else {
            setMode("create");
            setProblemId(null);
        }
    }, [params]);

    useEffect(() => {
        if (problemId && (props.problemLaveuse?.id !== parseInt(problemId)) && !props.loading && !props.error) {
            dispatch(problemsActions.fetchProblemLaveuse(problemId));
        }
    }, [props]);


    const handleSubmit = (values) => {
        if (mode === "create") {
            dispatch(problemsActions.createProblemLaveuse(values));
        } else {
            dispatch(problemsActions.updateProblemLaveuse(values, values.id));
        }
    }

    const initialValues = {
        id: props.problemLaveuse?.id ?? null,
        libelle: props.problemLaveuse?.libelle ?? '',
        importance: props.problemLaveuse?.importance ?? Constants.PROBLEMS_IMPORTANCE[0].code,
        category: props.problemLaveuse?.category ?? Constants.LAVEUSE_PROBLEMS_CATEGORIES[0].code
    }

    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouveau Problème" : "Modifier le problème"} icon={<Engineering/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={problemValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <ProblemLaveuseDataField values={values} errors={errors} handleChange={handleChange}
                                                 handleBlur={handleBlur}/>

                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting && !props.error}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        problemLaveuse: state.problems.problemLaveuse,
        loading: state.problems.loading,
        error: state.problems.error
    }
}

export default connect(mapStateToProps)(ProblemsLaveusesNewOrEdit);
