import React, {useState} from 'react';
import {Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import BuildIcon from '@mui/icons-material/Build';
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";
import {helper} from "../../../utils/helper.js";

function LaveusesTable({laveuses, ...props}) {
    const navigate = useNavigate()
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('client');
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [currentLaveuse, setCurrentLaveuse] = useState(null);

    // Table head cells
    const headCells = [
        {id: 'immatriculation', label: 'Immatriculation'},
        {id: 'description', label: 'Description'},
        {id: 'quartixId', label: 'Identifiant Quartix'},
        {id: 'operateur', label: 'Opérateur'},
        {id: 'statut', label: 'Statut'},
    ];


    const openDeleteConfirmModal = (f) => {
        setCurrentLaveuse(f)
        setLabel("Etes vous sûr de vouloir supprimer le laveuse : ")
        setObjectToDelete(f.libelle)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentLaveuse(null)
    };

    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_LAVEUSES_EDIT.replace(':id', id))
    }

    const handleJournalClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_LAVEUSES_JOURNAL.replace(':id', id))
    }

    const handleDelete = () => {
        props.onDelete(currentLaveuse)
        handleClose()
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getItemComparator = (item, comparator) => {
        return item[comparator];
    }
    const sortedData = () => {
        return laveuses.sort((a, b) => {
            if (order === 'asc') {
                return (getItemComparator(a, orderBy) > getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) > getItemComparator(a, orderBy)) ? -1 : 0;
            }
            return (getItemComparator(a, orderBy) < getItemComparator(b, orderBy)) ? 1 : (getItemComparator(b, orderBy) < getItemComparator(a, orderBy)) ? -1 : 0
        });
    }


    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="laveuses table" size="small">
                    <TableHeader headCells={headCells} order={order} orderBy={orderBy}
                                 onRequestSort={handleRequestSort}/>
                    <TableBody>
                        {laveuses && laveuses.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={10} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucun laveuse enregistrée"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {laveuses && sortedData().map((laveuse) => {
                            return (
                                <React.Fragment key={laveuse.id}>
                                    <TableRow hover onClick={() => handleJournalClick(laveuse.id)}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            {laveuse.immatriculation}
                                        </TableCell>
                                        <TableCell>
                                            {laveuse.description || "Non renseigné"}
                                        </TableCell>
                                        <TableCell>
                                            {laveuse.quartixId || "Non renseigné"}
                                        </TableCell>
                                        <TableCell>
                                            {laveuse.operateur?.libelle ?? "Non affectée"}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={helper.getLaveuseImportanceLibelle(laveuse.statut)}
                                                style={{
                                                    backgroundColor: helper.getLaveuseImportanceColor(laveuse.statut),
                                                    color: 'white'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="primary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleJournalClick(laveuse.id)
                                                            }}
                                                            startIcon={<BuildIcon/>}>
                                                        Journal
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(laveuse.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                        Editer
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button color="error" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDeleteConfirmModal(laveuse)
                                                            }}
                                                            startIcon={<CloseOutlinedIcon/>}>
                                                        Supprimer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentLaveuse} onDelete={handleDelete}/>
        </React.Fragment>
    )
}

export default LaveusesTable;
