import React, {useState} from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {getRole, isAdmin, Role} from "../../../../utils/roles";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Button from "@mui/material/Button";
import Constants from "../../../../utils/constants";


function UserDataField({...props}) {
    const {values, errors, exploitants, handleLogoChanged, mode, handleChange, handleBlur} = props;
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const getSelectableRoles = () => {
        return isAdmin() ? [Role.ROLE_EXPLOITANT, Role.ROLE_CLIENT, Role.ROLE_OPERATEUR] : [Role.ROLE_CLIENT, Role.ROLE_OPERATEUR];
    }

    const isRoleSelected = (role) => {
        return Array.isArray(values.roles) && values.roles.includes(role);
    }

    const getClassnameForSelectedRole = (role) => {
        if (role === Role.ROLE_EXPLOITANT) {
            return "toggle-button button-selected-primary";
        } else if (role === Role.ROLE_OPERATEUR) {
            return "toggle-button button-selected-secondary";
        } else if (role === Role.ROLE_CLIENT) {
            return "toggle-button button-selected-orange";
        }
    }

    const shouldAttachExploitant = (role) => {
        return role.includes(Role.ROLE_CLIENT) || role.includes(Role.ROLE_OPERATEUR);
    }

    const isClient = (role) => {
        return role.includes(Role.ROLE_CLIENT)
    }

    const isOperateur = (role) => {
        return role.includes(Role.ROLE_OPERATEUR)
    }

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant={"h5"} className={"sectionHeader"}>Role</Typography>
                    </Grid>

                    {/*ROLE FIELD*/}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Choix du rôle <span className="asterix">*</span>
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                name="roles"
                                value={values.roles}>
                                {({field, form}) => (
                                    <ToggleButtonGroup
                                        color={"primary"}
                                        exclusive
                                        size={"medium"}
                                        name="roles"
                                        value={values.roles}
                                        onChange={(event, newValue) => {
                                            form.setFieldValue('roles', [newValue]);
                                        }}
                                        aria-label={"Accreditation"}
                                    >
                                        {getSelectableRoles() && getSelectableRoles().map(r => {
                                                return (
                                                    <ToggleButton key={r} value={r} disabled={mode === "edit"}
                                                                  className={isRoleSelected(r) ? getClassnameForSelectedRole(r) : "toggle-button"}>{getRole([r])}</ToggleButton>
                                                )
                                            }
                                        )}
                                    </ToggleButtonGroup>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/*EXPLOITANT FIELD*/}
                    {shouldAttachExploitant(values.roles) &&
                        <Grid item container alignItems={"center"} className={"mt-2"}>
                            <Grid item xs={3}>
                                Exploitant à rattacher <span className="asterix">*</span>
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    name="exploitant"
                                    value={values.exploitant}>
                                    {({field, form}) => (
                                        <FormControl fullWidth error={Boolean(errors.exploitant)}>
                                            <InputLabel id="label-exploitant">Sélectionner l'exploitant :</InputLabel>
                                            <Select
                                                labelId="label-exploitant"
                                                label="Sélectionner l'exploitant :"
                                                variant="outlined"
                                                name="exploitant"
                                                value={values.exploitant}
                                                onChange={handleChange}
                                            >
                                                {exploitants && exploitants.map((e) => {
                                                    return (
                                                        <MenuItem key={e.id} value={e["@id"]}>
                                                            {e.libelle}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {form.errors.exploitant &&
                                                form.touched.exploitant &&
                                                String(form.errors.exploitant) &&
                                                <FormHelperText error={true}>{form.errors.exploitant}</FormHelperText>}
                                        </FormControl>
                                    )
                                    }
                                </Field>
                            </Grid>
                        </Grid>
                    }

                    {/*EMAIL NOTIFICATION FIELD*/}
                    {isClient(values.roles) &&
                        <>
                            <Grid item container alignItems={"center"} className={"mt-1 pt-1"}>
                                <Grid item xs={5}>
                                    Notifier si lavage impossible ?
                                </Grid>
                                <Grid item xs={7}>
                                    <Field
                                        type="text"
                                        value={values.emailNotificationsLavageImpossible ? values.emailNotificationsLavageImpossible : false}>
                                        {({field, form}) => (
                                            <ToggleButtonGroup
                                                color={"primary"}
                                                exclusive
                                                size={"medium"}
                                                name="emailNotificationsLavageImpossible"
                                                value={values.emailNotificationsLavageImpossible}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) form.setFieldValue('emailNotificationsLavageImpossible', newValue);
                                                }}
                                                aria-label={"Notifier lavage impossible"}
                                            >
                                                <ToggleButton value={false}
                                                              className={values.emailNotificationsLavageImpossible === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                                <ToggleButton value={true}
                                                              className={values.emailNotificationsLavageImpossible === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                                            </ToggleButtonGroup>

                                        )}
                                    </Field>

                                </Grid>
                            </Grid>

                            <Grid item container alignItems={"center"} className={"mt-1 pt-1"}>
                                <Grid item xs={5}>
                                    Notifier si maintenance nécessaire ?
                                </Grid>
                                <Grid item xs={7}>
                                    <Field
                                        type="text"
                                        value={values.emailNotificationsMaintenance ? values.emailNotificationsMaintenance : false}>
                                        {({field, form}) => (
                                            <ToggleButtonGroup
                                                color={"primary"}
                                                exclusive
                                                size={"medium"}
                                                name="emailNotificationsMaintenance"
                                                value={values.emailNotificationsMaintenance}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) form.setFieldValue('emailNotificationsMaintenance', newValue);
                                                }}
                                                aria-label={"Notifier lavage maintenance"}
                                            >
                                                <ToggleButton value={false}
                                                              className={values.emailNotificationsMaintenance === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                                <ToggleButton value={true}
                                                              className={values.emailNotificationsMaintenance === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                                            </ToggleButtonGroup>

                                        )}
                                    </Field>

                                </Grid>
                            </Grid>

                            <Grid item container alignItems={"center"} className={"mt-1 pt-1"}>
                                <Grid item xs={5}>
                                    Notifier si lavage réussi ?
                                </Grid>
                                <Grid item xs={7}>
                                    <Field
                                        type="text"
                                        value={values.emailNotificationsLavageDone ? values.emailNotificationsLavageDone : false}>
                                        {({field, form}) => (
                                            <ToggleButtonGroup
                                                color={"primary"}
                                                exclusive
                                                size={"medium"}
                                                name="emailNotificationsLavageDone"
                                                value={values.emailNotificationsLavageDone}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) form.setFieldValue('emailNotificationsLavageDone', newValue);
                                                }}
                                                aria-label={"Notifier lavage reussi"}
                                            >
                                                <ToggleButton value={false}
                                                              className={values.emailNotificationsLavageDone === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                                <ToggleButton value={true}
                                                              className={values.emailNotificationsLavageDone === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                                            </ToggleButtonGroup>

                                        )}
                                    </Field>

                                </Grid>
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} className={"mt-2"}>
                        <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
                    </Grid>

                    {/*LIBELLE FIELD*/}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Libellé
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                value={values.libelle}>
                                {({field, form}) => (
                                    <TextField
                                        error={
                                            Boolean(form.errors.libelle && form.touched.libelle)
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.libelle}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name="libelle"
                                        label="Libellé"
                                        autoFocus
                                        helperText={
                                            form.errors.libelle &&
                                            form.touched.libelle &&
                                            String(form.errors.libelle)
                                        }/>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/*MAIL FIELD*/}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Adresse email <span className="asterix">*</span>
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                value={values.email ? values.email : ''}>
                                {({field, form}) => (
                                    <TextField
                                        error={
                                            Boolean(form.errors.email && form.touched.email)
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name="email"
                                        label="Adresse email"
                                        helperText={
                                            form.errors.email &&
                                            form.touched.email &&
                                            String(form.errors.email)
                                        }/>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/*PASSWORD FIELD*/}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Mot de passe {mode === "create" && <span className="asterix">*</span>}
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                value={values.password ? values.password : ''}>
                                {({field, form}) => (
                                    <TextField
                                        error={
                                            Boolean(form.errors.password && form.touched.password)
                                        }
                                        type={showPassword ? "text" : "password"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        helperText={
                                            form.errors.password &&
                                            form.touched.password &&
                                            String(form.errors.password)
                                        }/>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/*RFID FIELD*/}
                    <Grid item container alignItems={"center"}>
                        <Grid item xs={3}>
                            Données RFID
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                value={values.rfid}>
                                {({field, form}) => (
                                    <TextField
                                        error={
                                            Boolean(form.errors.rfid && form.touched.rfid)
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.rfid ? values.rfid : ''}
                                        variant="outlined"
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        name="rfid"
                                        label="Données RFID"
                                        helperText={
                                            form.errors.rfid &&
                                            form.touched.rfid &&
                                            String(form.errors.rfid)
                                        }/>
                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/*ACCREDITED FIELD*/}
                    <Grid item container alignItems={"center"} className={"mt-1 pt-1"}>
                        <Grid item xs={3}>
                            Accréditer l'utilisateur ?
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                type="text"
                                value={values.isAccredited ? values.isAccredited : false}>
                                {({field, form}) => (
                                    <ToggleButtonGroup
                                        color={"primary"}
                                        exclusive
                                        size={"medium"}
                                        name="isAccredited"
                                        value={values.isAccredited}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null) form.setFieldValue('isAccredited', newValue);
                                        }}
                                        aria-label={"Accreditation"}
                                    >
                                        <ToggleButton value={false}
                                                      className={values.isAccredited === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                        <ToggleButton value={true}
                                                      className={values.isAccredited === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                                    </ToggleButtonGroup>

                                )}
                            </Field>
                        </Grid>
                    </Grid>

                    {/* RH FEATURE ENABLED FIELD*/}
                    {isOperateur(values.roles) &&
                        <Grid item container alignItems={"center"} className={"mt-1 pt-1"}>
                            <Grid item xs={3}>
                                Activer la gestion des heures ?
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    value={values.RHFeatureEnabled ? values.RHFeatureEnabled : false}>
                                    {({field, form}) => (
                                        <ToggleButtonGroup
                                            color={"primary"}
                                            exclusive
                                            size={"medium"}
                                            name="RHFeatureEnabled"
                                            value={values.RHFeatureEnabled}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) form.setFieldValue('RHFeatureEnabled', newValue);
                                            }}
                                            aria-label={"RHFeatureEnabled"}
                                        >
                                            <ToggleButton value={false}
                                                          className={values.RHFeatureEnabled === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                            <ToggleButton value={true}
                                                          className={values.RHFeatureEnabled === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                                        </ToggleButtonGroup>

                                    )}
                                </Field>

                            </Grid>
                        </Grid>
                    }


                </Grid>
            </Grid>
            <Grid item xs={1}>
                {/*SEPARATOR*/}
            </Grid>
            <Grid item xs={5}>
                <Grid item xs={12}>
                    <Typography variant={"h5"} className={"sectionHeader"}>Logo</Typography>
                </Grid>

                {/*LOGO FIELD*/}
                <Grid item container alignItems={"center"}>
                    <Grid item xs={12}>
                        <Field name="logo">
                            {({form}) => (
                                <FormControl fullWidth variant="outlined">
                                    <Grid container>
                                        <input
                                            id="logo-input"
                                            type="file"
                                            accept="image/*"
                                            style={{display: 'none'}}
                                            onChange={(event) => {
                                                handleLogoChanged(event, form)
                                            }}
                                        />
                                        <label htmlFor="logo-input">
                                            <Button variant="contained" component="span">
                                                Charger un logo
                                            </Button>
                                        </label>
                                    </Grid>
                                    {form.errors.logo && form.touched.logo && (
                                        <FormHelperText error={true}>{form.errors.logo}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={9} className={"mt-2"}>
                        {values.logoUrl && (
                            <img src={Constants.API_ASSETS_USER_LOGO + values.logoUrl} className={"logo-preview"}
                                 alt={"Logo utilisateur"}/>
                        )}

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default UserDataField;
