import React, {useState} from 'react';
import {Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";

function RHDataTable({rhdatas, ...props}) {
    const navigate = useNavigate()
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [currentPrestation, setCurrentPrestation] = useState(null);
    let currentType = null;

    // Table head cells
    const headCells = [
        {id: 'libelle', label: 'Opérateur'},
        {id: 'tauxHoraire', label: 'Tx H'},
        {id: 'tauxChargesPatronales', label: 'Tx Charges Patronales'},
        {id: 'forfaitPanierRepas', label: 'Panier Repas'},
        {id: 'forfaitDeplacement', label: 'Forfait déplacement'},
    ];


    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_RH_DATA_EDIT.replace(':id', id))
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="problems table" size="small">
                    <TableHeader headCells={headCells}/>
                    <TableBody>
                        {rhdatas && rhdatas.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucune données enregistrée"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {rhdatas && rhdatas.map((rhdata, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TableRow hover
                                              onClick={() => handleEditClick(rhdata.id)}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            {rhdata.operateur?.libelle}
                                        </TableCell>
                                        <TableCell>
                                            {rhdata.tauxHoraire + ' €'}
                                        </TableCell>
                                        <TableCell>
                                            {rhdata.tauxChargesPatronales + ' %'}
                                        </TableCell>
                                        <TableCell>
                                            {rhdata.forfaitPanierRepas + ' €'}
                                        </TableCell>
                                        <TableCell>

                                            {rhdata.forfaitDeplacement + ' €'}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(rhdata.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                        Editer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default RHDataTable;
