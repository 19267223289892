import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Loader from "./layout/loading/loader";
import {Formik} from "formik";
import './UsersNewOrEdit.css'
import {ref, object, date, string} from 'yup';
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SubmitButton from "./layout/forms/SubmitButton";
import PageTitle from "./layout/PageTitle";
import {heuresActions} from "../../modules/heures/heures.actions.js";
import PeriodePaieDataField from "./rh/form/PeriodePaieDataField.js";


const validationSchema = object({
    libelle: string().min(1).required("Le libellé est requis").typeError("Texte invalide"),
    startDate: date()
        .required("La date de début est requise")
        .typeError("Date de début invalide"),
    endDate: date()
        .required("La date de fin est requise")
        .typeError("Date de fin invalide")
        .min(ref('startDate'), "La date de fin doit être ultérieure ou égale à la date de début"),
});

function RHPeriodePaieNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [periodePaieId, setPeriodePaieId] = useState(null);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setPeriodePaieId(params.id);
        } else {
            setMode("create");
            setPeriodePaieId(null);
        }
    }, [params]);

    useEffect(() => {
        if (periodePaieId && (props.periodePaie?.id !== parseInt(periodePaieId)) && !props.loading && !props.error) {
            dispatch(heuresActions.fetchPeriodePaie(periodePaieId));
        }
    }, [props, dispatch, params.type, periodePaieId]);


    const handleSubmit = (values) => {
        if (mode === "create") {
            dispatch(heuresActions.createPeriodePaie(values));
        } else {
            dispatch(heuresActions.updatePeriodePaie(values.id, values));
        }
    }

    const initialValues = {
        id: props.periodePaie?.id ?? null,
        libelle: props.periodePaie?.libelle ?? '',
        startDate: props.periodePaie?.startDate ?? '',
        endDate: props.periodePaie?.endDate ?? ''
    }

    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouvelle période" : "Modifier la période"}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      dirty,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <PeriodePaieDataField values={values} errors={errors} handleChange={handleChange}
                                              handleBlur={handleBlur}/>

                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting && !props.error}
                                              disabled={!isValid || !dirty}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        periodePaie: state.heures.periodePaie,
        loading: state.heures.loading,
        error: state.heures.error
    }
}

export default connect(mapStateToProps)(RHPeriodePaieNewOrEdit);
