import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const problemsService = {
    fetchProblems,
    fetchProblem,
    deleteProblem,
    createProblem,
    updateProblem,
    fetchProblemLaveuses,
    fetchProblemLaveuse,
    deleteProblemLaveuse,
    createProblemLaveuse,
    updateProblemLaveuse,
};

function fetchProblems() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_PROBLEMS, requestOptions)
        .then(handleResponse)
        .then(problems => {
            return problems["hydra:member"]
        });
}

function fetchProblem(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_PROBLEM.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(problem => {
            return problem
        });
}

function createProblem(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_PROBLEMS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateProblem(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_PROBLEM.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteProblem(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_PROBLEM.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}


function fetchProblemLaveuses() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_PROBLEMS_LAVEUSE, requestOptions)
        .then(handleResponse)
        .then(problems => {
            return problems["hydra:member"]
        });
}

function fetchProblemLaveuse(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_PROBLEM_LAVEUSE.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(problem => {
            return problem
        });
}

function createProblemLaveuse(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_PROBLEMS_LAVEUSE;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updateProblemLaveuse(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_PROBLEM_LAVEUSE.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deleteProblemLaveuse(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_PROBLEM_LAVEUSE.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
