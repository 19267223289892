import {heuresConstants} from "./heures.constants.js";
import {heuresService} from "./heures.service.js";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";
import {laveusesService} from "../laveuses/laveuses.service.js";
import {laveusesConstants} from "../laveuses/laveuses.constants.js";

export const heuresActions = {
    fetchHeures,
    updateFeuilleDeTemps,
    fetchFicheChantier,
    exportSuiviHeures,
    createPeriodePaie,
    updatePeriodePaie,
    deletePeriodePaie,
    fetchPeriodePaie,
    fetchPeriodePaies,
    createPoste,
    clearHeures
};

function fetchHeures(startDate, endDate) {
    return dispatch => {
        dispatch(request());

        heuresService.fetchHeures(startDate, endDate)
            .then(
                heures => {
                    dispatch(success(heures));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.HEURES_FETCH_REQUEST}
    }

    function success(heures) {
        return {type: heuresConstants.HEURES_FETCH_SUCCESS, heures}
    }

    function failure(error) {
        return {type: heuresConstants.HEURES_FETCH_FAILURE, error}
    }
}

function updateFeuilleDeTemps(id, values) {
    return dispatch => {
        dispatch(request());

        return heuresService.updateFeuilleDeTemps(id, values)
            .then(
                data => {
                    dispatch(success(data));
                    return data; // Resolves the promise with the updated data
                },
                error => {
                    dispatch(failure(error.toString()));
                    throw error; // Rejects the promise with the error
                }
            );
    };

    function request() {
        return {type: heuresConstants.FEUILLEDETEMPS_UPDATE_REQUEST};
    }

    function success(fdt) {
        return {type: heuresConstants.FEUILLEDETEMPS_UPDATE_SUCCESS, fdt};
    }

    function failure(error) {
        return {type: heuresConstants.FEUILLEDETEMPS_UPDATE_FAILURE, error};
    }
}

function fetchFicheChantier(startDate, endDate, operateurId) {
    return dispatch => {
        dispatch(request());

        heuresService.fetchFicheChantier(startDate, endDate, operateurId)
            .then(
                ficheChantier => {
                    dispatch(success(ficheChantier));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.FICHE_CHANTIER_FETCH_REQUEST}
    }

    function success(ficheChantier) {
        return {type: heuresConstants.FICHE_CHANTIER_FETCH_SUCCESS, ficheChantier}
    }

    function failure(error) {
        return {type: heuresConstants.FICHE_CHANTIER_FETCH_FAILURE, error}
    }
}

function exportSuiviHeures(start, end) {
    return dispatch => {
        dispatch({type: heuresConstants.EXPORT_SUIVI_HEURES_REQUEST});

        heuresService.exportSuiviHeures(start, end)
            .then(() => {
                dispatch({type: heuresConstants.EXPORT_SUIVI_HEURES_SUCCESS});
            })
            .catch(error => {

                dispatch({type: heuresConstants.EXPORT_SUIVI_HEURES_FAILURE});
                console.error('Erreur lors de l\'export :', error);
            });
    };
}

function fetchPeriodePaies() {
    return dispatch => {
        dispatch(request());

        heuresService.fetchPeriodePaies()
            .then(
                periodePaies => {
                    dispatch(success(periodePaies));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_REQUEST}
    }

    function success(periodePaies) {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_SUCCESS, periodePaies}
    }

    function failure(error) {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_FAILURE, error}
    }
}

function fetchPeriodePaie(id) {
    return dispatch => {
        dispatch(request());

        heuresService.fetchPeriodePaie(id)
            .then(
                periodePaie => {
                    dispatch(success(periodePaie));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_ONE_REQUEST}
    }

    function success(periodePaie) {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_ONE_SUCCESS, periodePaie}
    }

    function failure(error) {
        return {type: heuresConstants.PERIODE_PAIES_FETCH_ONE_FAILURE, error}
    }
}

function createPeriodePaie(values) {
    return dispatch => {
        dispatch(request());

        heuresService.createPeriodePaie(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_RH_PERIODE_PAIES);
                },
                error => {
                    if (error === '') {
                        error = "Erreur lors de la création"
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.PERIODE_PAIES_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La période a bien été créé.")
        return {type: heuresConstants.PERIODE_PAIES_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: heuresConstants.PERIODE_PAIES_CREATE_FAILURE, error}
    }
}

function createPoste(values) {
    return dispatch => {
        dispatch(request());

        heuresService.createPoste(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_RH_SUIVIHEURES);
                },
                error => {
                    if (error === '') {
                        error = "Erreur lors de la création"
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.POSTE_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Les heures ont bien été saisies.")
        return {type: heuresConstants.POSTE_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error)
        return {type: heuresConstants.POSTE_CREATE_FAILURE, error}
    }
}

function updatePeriodePaie(id, values) {
    return dispatch => {
        dispatch(request());

        heuresService.updatePeriodePaie(id, values)
            .then(
                (updated) => {
                    dispatch(success(updated));
                    history.replace(Constants.APP_ROUTE_RH_PERIODE_PAIES);
                },
                (error) => {
                    // 4) En cas d'erreur
                    if (!error) {
                        error = "Erreur lors de la mise à jour";
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.PERIODE_PAIES_UPDATE_REQUEST};
    }

    function success(periodePaie) {
        toastHelper.displaySuccess("La période a bien été mise à jour.");
        return {
            type: heuresConstants.PERIODE_PAIES_UPDATE_SUCCESS,
            periodePaie,
        };
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue lors de la mise à jour.");
        return {
            type: heuresConstants.PERIODE_PAIES_UPDATE_FAILURE,
            error
        };
    }
}

function deletePeriodePaie(id) {
    return dispatch => {
        dispatch(request());

        heuresService.deletePeriodePaie(id)
            .then(
                () => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_RH_PERIODE_PAIES);
                },
                (error) => {
                    if (!error) {
                        error = "Erreur lors de la suppression";
                    }
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: heuresConstants.PERIODE_PAIES_DELETE_REQUEST};
    }

    function success(id) {
        toastHelper.displaySuccess("La période a bien été supprimée.");
        // Si tu veux enlever l'élément en local, le reducer le fera
        // via un filter => see case PERIODE_PAIES_DELETE_SUCCESS
        return {
            type: heuresConstants.PERIODE_PAIES_DELETE_SUCCESS,
            id
        };
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, impossible de supprimer la période.");
        return {
            type: heuresConstants.PERIODE_PAIES_DELETE_FAILURE,
            error
        };
    }
}


function clearHeures() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: heuresConstants.HEURES_CLEAR_REQUEST}
    }
}
