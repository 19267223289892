export const problemsConstants = {
    PROBLEMS_FETCH_REQUEST: "PROBLEMS_FETCH_REQUEST",
    PROBLEMS_FETCH_SUCCESS: "PROBLEMS_FETCH_SUCCESS",
    PROBLEMS_FETCH_FAILURE: "PROBLEMS_FETCH_FAILURE",

    PROBLEMS_FETCH_ONE_REQUEST: "PROBLEMS_FETCH_ONE_REQUEST",
    PROBLEMS_FETCH_ONE_SUCCESS: "PROBLEMS_FETCH_ONE_SUCCESS",
    PROBLEMS_FETCH_ONE_FAILURE: "PROBLEMS_FETCH_ONE_FAILURE",

    PROBLEMS_CREATE_REQUEST: "PROBLEMS_CREATE_REQUEST",
    PROBLEMS_CREATE_SUCCESS: "PROBLEMS_CREATE_SUCCESS",
    PROBLEMS_CREATE_FAILURE: "PROBLEMS_CREATE_FAILURE",

    PROBLEMS_UPDATE_REQUEST: "PROBLEMS_UPDATE_REQUEST",
    PROBLEMS_UPDATE_SUCCESS: "PROBLEMS_UPDATE_SUCCESS",
    PROBLEMS_UPDATE_FAILURE: "PROBLEMS_UPDATE_FAILURE",

    PROBLEMS_DELETE_REQUEST: "PROBLEMS_DELETE_REQUEST",
    PROBLEMS_DELETE_SUCCESS: "PROBLEMS_DELETE_SUCCESS",
    PROBLEMS_DELETE_FAILURE: "PROBLEMS_DELETE_FAILURE",

    PROBLEMS_CLEAR_REQUEST: "PROBLEMS_CLEAR_REQUEST",

    PROBLEMS_LAVEUSE_FETCH_REQUEST: "PROBLEMS_LAVEUSE_FETCH_REQUEST",
    PROBLEMS_LAVEUSE_FETCH_SUCCESS: "PROBLEMS_LAVEUSE_FETCH_SUCCESS",
    PROBLEMS_LAVEUSE_FETCH_FAILURE: "PROBLEMS_LAVEUSE_FETCH_FAILURE",

    PROBLEMS_LAVEUSE_FETCH_ONE_REQUEST: "PROBLEMS_LAVEUSE_FETCH_ONE_REQUEST",
    PROBLEMS_LAVEUSE_FETCH_ONE_SUCCESS: "PROBLEMS_LAVEUSE_FETCH_ONE_SUCCESS",
    PROBLEMS_LAVEUSE_FETCH_ONE_FAILURE: "PROBLEMS_LAVEUSE_FETCH_ONE_FAILURE",

    PROBLEMS_LAVEUSE_CREATE_REQUEST: "PROBLEMS_LAVEUSE_CREATE_REQUEST",
    PROBLEMS_LAVEUSE_CREATE_SUCCESS: "PROBLEMS_LAVEUSE_CREATE_SUCCESS",
    PROBLEMS_LAVEUSE_CREATE_FAILURE: "PROBLEMS_LAVEUSE_CREATE_FAILURE",

    PROBLEMS_LAVEUSE_UPDATE_REQUEST: "PROBLEMS_LAVEUSE_UPDATE_REQUEST",
    PROBLEMS_LAVEUSE_UPDATE_SUCCESS: "PROBLEMS_LAVEUSE_UPDATE_SUCCESS",
    PROBLEMS_LAVEUSE_UPDATE_FAILURE: "PROBLEMS_LAVEUSE_UPDATE_FAILURE",

    PROBLEMS_LAVEUSE_DELETE_REQUEST: "PROBLEMS_LAVEUSE_DELETE_REQUEST",
    PROBLEMS_LAVEUSE_DELETE_SUCCESS: "PROBLEMS_LAVEUSE_DELETE_SUCCESS",
    PROBLEMS_LAVEUSE_DELETE_FAILURE: "PROBLEMS_LAVEUSE_DELETE_FAILURE",

    PROBLEMS_LAVEUSE_CLEAR_REQUEST: "PROBLEMS_LAVEUSE_CLEAR_REQUEST",
};
