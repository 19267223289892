import {rhdataConstants} from "./rhdata.constants.js";
import {rhdataService} from "./rhdata.service.js";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const rhdataActions = {
    fetchRhdatas,
    fetchRhdata,
    deleteRhdata,
    createRhdata,
    updateRhdata,
    clearRhdatas
};

function fetchRhdatas(pagination = false) {
    return dispatch => {
        dispatch(request());

        rhdataService.fetchRhdatas(pagination)
            .then(
                rhdata => {
                    let res = rhdata;
                    try {
                        res = rhdata.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: rhdataConstants.RHDATA_FETCH_REQUEST}
    }

    function success(rhdatas) {
        return {type: rhdataConstants.RHDATA_FETCH_SUCCESS, rhdatas}
    }

    function failure(error) {
        return {type: rhdataConstants.RHDATA_FETCH_FAILURE, error}
    }
}

function fetchRhdata(id) {
    return dispatch => {
        dispatch(request());
        rhdataService.fetchRhdata(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: rhdataConstants.RHDATA_FETCH_ONE_REQUEST}
    }

    function success(rhdata) {
        return {type: rhdataConstants.RHDATA_FETCH_ONE_SUCCESS, rhdata}
    }

    function failure(error) {
        return {type: rhdataConstants.RHDATA_FETCH_ONE_FAILURE, error}
    }
}

function deleteRhdata(id, navigate) {
    return dispatch => {
        dispatch(request());

        rhdataService.deleteRhdata(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_RH_DATA);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: rhdataConstants.RHDATA_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La prestation a bien été supprimée.")
        return {type: rhdataConstants.RHDATA_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: rhdataConstants.RHDATA_DELETE_FAILURE, error}
    }
}

function createRhdata(values, navigate) {
    return dispatch => {
        dispatch(request());

        rhdataService.createRhdata(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_RH_DATA);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: rhdataConstants.RHDATA_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La prestation a bien été créé.")
        return {type: rhdataConstants.RHDATA_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: rhdataConstants.RHDATA_CREATE_FAILURE, error}
    }
}

function updateRhdata(values, navigate) {
    return dispatch => {
        dispatch(request());
        rhdataService.updateRhdata(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_RH_DATA);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: rhdataConstants.RHDATA_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Les données ont bien été modifiées.")
        return {type: rhdataConstants.RHDATA_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: rhdataConstants.RHDATA_UPDATE_FAILURE, error}
    }
}

function clearRhdatas() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: rhdataConstants.RHDATA_CLEAR_REQUEST}
    }
}
